import ReactDOM from 'react-dom';
import Registration from './registration';
import axios from 'axios';
import lang from './language';
import indexedDBConnection from './indexedDBConnection'



const state = {
    globalId: null,
    language:"ukr", //вибір мови сайту
    cityNamePattern: /^(?:[A-ZА-ЯЇІЄ'][a-zа-яїіє']*|['\- ]?[A-ZА-ЯЇІЄ'][a-zа-яїіє']*)(?:[ \-][A-ZА-ЯЇІЄ'][a-zа-яїіє']*)*$/,
    isMediumScreen: window.innerWidth >= 768 && window.innerWidth <= 1023 ? true : false,
    competitionResult: [], //результати змагань
    gridsReport: [],//звіт про генерацію сіток
    grids: [], // згенеровані сітки
    gridsDB: [],//сітки збережені в БД
    gridsResult: [],// сітки з результатами турниру
    saveGridsFunctionStart: false,// одночасний запуск функції збереження сіток у компонентах
    printGridsFunctionStart: false,// одночасний запуск функції друку сіток у компонентах
    printGridsCompleteData: false,//завершення збору данних для друку
    gridsForDB: [],//сітки приготовані для збереження в БД
    gridsForPrint: [],//сітки приготовані для друку
    competitionSystem:[//не змінювати!!!! 
        'Олімпійська система',       
        'Система виключення',            
    ],
    poomsaeOptions:{
        category:{
            traditional:{
                personal:false,
                couple:false,
                team:false
            },
            freestyle:{
                personal:false,
                couple:false,
                team:false
            },
            mixPoomsae:{
                personal:false,
                couple:false,
                team:false,
                mixedTeam:false
            }
        },
        competitionSystem:null,
        classB:false,
        poomsaeLimit:2,
        perfomanceNumber:2,
        judge:[]
    },
   
/////////////////////////////////////////////////////////////////////////////////////////
// api:
// {
//     login: 'http://localhost/gridstkd/server/login.php',
//     registration: 'http://localhost/gridstkd/server/registration.php',
//     addSportClub: 'http://localhost/gridstkd/server/addSportClub.php',
//     getSportClub: 'http://localhost/gridstkd/server/getSportClub.php',
//     getAthletes: 'http://localhost/gridstkd/server/getAthletes.php',
//     uploadAvatar: 'http://localhost/gridstkd/server/uploadAvatar.php',
//     updateProfile: 'http://localhost/gridstkd/server/updateProfile.php',
//     uploadLogoTourn: 'http://localhost/gridstkd/server/uploadLogoTourn.php',
//     addCompetition: 'http://localhost/gridstkd/server/addCompetition.php',
//     uploadPdfTourn: 'http://localhost/gridstkd/server/uploadPdfTourn.php',
//     getCompetition: 'http://localhost/gridstkd/server/getCompetition.php',
//     addNewAthletes: 'http://localhost/gridstkd/server/addNewAthletes.php',
//     getAthletesForComp: 'http://localhost/gridstkd/server/getAthletesForComp.php',
//     postCompetitionIdToAthletProfile: 'http://localhost/gridstkd/server/postCompetitionIdToAthletProfile.php',
//     postErrorMassage: 'http://localhost/gridstkd/server/postErrorMassage.php',
//     getMail: 'http://localhost/gridstkd/server/getMail.php',
//     updateMailIsRead: 'http://localhost/gridstkd/server/updateMailIsRead.php',
//     updateCompetition: 'http://localhost/gridstkd/server/updateCompetition.php',
//     acceptAthleteToClub: 'http://localhost/gridstkd/server/acceptAthleteToClub.php',
//     gridsCompetition: 'http://localhost/gridstkd/server/gridsCompetition.php',
//     emailSend: 'http://localhost/gridstkd/server/emailSend.php',
//     updateCompetitionResult: 'http://localhost/gridstkd/server/updateCompetitionResult.php',
//     postCompetitionResultToAthletProfile: 'http://localhost/gridstkd/server/postCompetitionResultToAthletProfile.php',
//     setCoach: 'http://localhost/gridstkd/server/setCoach.php',
//     setJudge: 'http://localhost/gridstkd/server/setJudge.php',
//     setModerator: 'http://localhost/gridstkd/server/setModerator.php',
//     setActivFTU: 'http://localhost/gridstkd/server/setActivFTU.php',
//     setRegionLeader: 'http://localhost/gridstkd/server/setRegionLeader.php',
//     uploadSportClubLogo: 'http://localhost/gridstkd/server/uploadSportClubLogo.php',
//     updateSportClub: 'http://localhost/gridstkd/server/updateSportClub.php',
//     deleteSportClub: 'http://localhost/gridstkd/server/deleteSportClub.php',
//     removeAthletesFromSportClub: 'http://localhost/gridstkd/server/removeAthletesFromSportClub.php',
//     setScaleAthletes: 'http://localhost/gridstkd/server/setScaleAthletes.php',
//     deleteSportClubLogo: 'http://localhost/gridstkd/server/deleteSportClubLogo.php',
//     deleteProfileAvatar: 'http://localhost/gridstkd/server/deleteProfileAvatar.php',
//     deleteUserProfile: 'http://localhost/gridstkd/server/deleteUserProfile.php',
//     deleteTournReglamentPDF: 'http://localhost/gridstkd/server/deleteTournReglamentPDF.php',
//     deleteTournLogo: 'http://localhost/gridstkd/server/deleteTournLogo.php',
//     deleteCompetition: 'http://localhost/gridstkd/server/deleteCompetition.php',
//     deleteMailMessage: 'http://localhost/gridstkd/server/deleteMailMessage.php',
//     getArchive: 'http://localhost/gridstkd/server/getArchive.php',
//     saveResultInArchive: 'http://localhost/gridstkd/server/saveResultInArchive.php',
//     clearCompetition: 'http://localhost/gridstkd/server/clearCompetition.php',
//     postDateForBlockProfile: 'http://localhost/gridstkd/server/postDateForBlockProfile.php',
//     setActivSite: 'http://localhost/gridstkd/server/setActivSite.php',
//     sendPassToEMail: 'http://localhost/gridstkd/server/sendPassToEMail.php',
//     updateSportClubNameInAthleteProfile: 'http://localhost/gridstkd/server/updateSportClubNameInAthleteProfile.php',
//     deleteArchiveData: 'http://localhost/gridstkd/server/deleteArchiveData.php',
//     updateArchive: 'http://localhost/gridstkd/server/updateArchive.php',
//     foodEnrty: 'http://localhost/gridstkd/server/foodEnrty.php',
//     compititionChat: 'http://localhost/gridstkd/server/compititionChat.php',
//     getCompetitionById: 'http://localhost/gridstkd/server/getCompetitionById.php',
//     getAthletesForPoomsaeComp: 'http://localhost/gridstkd/server/getAthletesForPoomsaeComp.php',
//     addNewAthletesOnPoomsae: 'http://localhost/gridstkd/server/addNewAthletesOnPoomsae.php',
//     appoindCoachesOnCompetition:'http://localhost/gridstkd/server/appoindCoachesOnCompetition.php',
//     controlDeskResultToDB:'http://localhost/server/controlDeskResultToDB.php',
//     controlDeskDataToDB:'http://localhost/server/controlDeskDataToDB.php',
//     controlDeskResultRead:'http://localhost/server/controlDeskResultRead.php',
//     controlDeskDataRead:'http://localhost/server/controlDeskDataRead.php',
// poomsaeCortResultRead:'http://localhost/server/poomsaeCortResultRead.php',
// poomsaeCortResultToDB:'http://localhost/server/poomsaeCortResultToDB.php',
//     poomsaeReservCopyRead:'http://localhost/server/poomsaeReservCopyRead.php',
//     poomsaeReservCopyToDB:'http://localhost/server/poomsaeReservCopyToDB.php',
//     postLastChangesDate:'http://localhost/server/postLastChangesDate.php',
//    
// },
// imgAdress: {
//     sportClubLogo: 'http://localhost/gridstkd/public/img/logosSportclubs/',
//     newCompetitionLogo: 'http://localhost/gridstkd/public/img/logosTourn/',
//     newCompetitionReglament: 'http://localhost/gridstkd/public/img/reglametsTourn/',
//     userProfilePhoto: 'http://localhost/gridstkd/public/img/avatar/',
// },

    ///////////////////////////////////////////////////////////////////////////////
    api: 
    {
        login: 'https://www.gridstkd.com.ua/server/login.php',
        registration: 'https://www.gridstkd.com.ua/server/registration.php',
        addSportClub: 'https://www.gridstkd.com.ua/server/addSportClub.php',
        getSportClub: 'https://www.gridstkd.com.ua/server/getSportClub.php',
        getAthletes: 'https://www.gridstkd.com.ua/server/getAthletes.php',
        uploadAvatar: 'https://www.gridstkd.com.ua/server/uploadAvatar.php',
        updateProfile: 'https://www.gridstkd.com.ua/server/updateProfile.php',
        uploadLogoTourn: 'https://www.gridstkd.com.ua/server/uploadLogoTourn.php',
        addCompetition: 'https://www.gridstkd.com.ua/server/addCompetition.php',
        uploadPdfTourn: 'https://www.gridstkd.com.ua/server/uploadPdfTourn.php',
        getCompetition: 'https://www.gridstkd.com.ua/server/getCompetition.php',
        addNewAthletes: 'https://www.gridstkd.com.ua/server/addNewAthletes.php',
        getAthletesForComp: 'https://www.gridstkd.com.ua/server/getAthletesForComp.php',
        postCompetitionIdToAthletProfile: 'https://www.gridstkd.com.ua/server/postCompetitionIdToAthletProfile.php',
        postErrorMassage: 'https://www.gridstkd.com.ua/server/postErrorMassage.php',
        getMail: 'https://www.gridstkd.com.ua/server/getMail.php',
        updateMailIsRead: 'https://www.gridstkd.com.ua/server/updateMailIsRead.php',
        updateCompetition: 'https://www.gridstkd.com.ua/server/updateCompetition.php',
        acceptAthleteToClub: 'https://www.gridstkd.com.ua/server/acceptAthleteToClub.php',
        gridsCompetition: 'https://www.gridstkd.com.ua/server/gridsCompetition.php',
        emailSend: 'https://www.gridstkd.com.ua/server/emailSend.php',
        updateCompetitionResult: 'https://www.gridstkd.com.ua/server/updateCompetitionResult.php',
        postCompetitionResultToAthletProfile: 'https://www.gridstkd.com.ua/server/postCompetitionResultToAthletProfile.php',
        setCoach: 'https://www.gridstkd.com.ua/server/setCoach.php',
        setJudge: 'https://www.gridstkd.com.ua/server/setJudge.php',
        setModerator: 'https://www.gridstkd.com.ua/server/setModerator.php',
        setActivFTU: 'https://www.gridstkd.com.ua/server/setActivFTU.php',
        setRegionLeader: 'https://www.gridstkd.com.ua/server/setRegionLeader.php',
        uploadSportClubLogo: 'https://www.gridstkd.com.ua/server/uploadSportClubLogo.php',
        updateSportClub: 'https://www.gridstkd.com.ua/server/updateSportClub.php',
        deleteSportClub: 'https://www.gridstkd.com.ua/server/deleteSportClub.php',
        removeAthletesFromSportClub: 'https://www.gridstkd.com.ua/server/removeAthletesFromSportClub.php',
        setScaleAthletes: 'https://www.gridstkd.com.ua/server/setScaleAthletes.php',
        deleteSportClubLogo: 'https://www.gridstkd.com.ua/server/deleteSportClubLogo.php',
        deleteProfileAvatar: 'https://www.gridstkd.com.ua/server/deleteProfileAvatar.php',
        deleteUserProfile: 'https://www.gridstkd.com.ua/server/deleteUserProfile.php',
        deleteTournReglamentPDF: 'https://www.gridstkd.com.ua/server/deleteTournReglamentPDF.php',
        deleteTournLogo: 'https://www.gridstkd.com.ua/server/deleteTournLogo.php',
        deleteCompetition: 'https://www.gridstkd.com.ua/server/deleteCompetition.php',
        deleteMailMessage: 'https://www.gridstkd.com.ua/server/deleteMailMessage.php',
        getArchive: 'https://www.gridstkd.com.ua/server/getArchive.php',
        saveResultInArchive: 'https://www.gridstkd.com.ua/server/saveResultInArchive.php',
        clearCompetition: 'https://www.gridstkd.com.ua/server/clearCompetition.php',
        postDateForBlockProfile: 'https://www.gridstkd.com.ua/server/postDateForBlockProfile.php',
        setActivSite: 'https://www.gridstkd.com.ua/server/setActivSite.php',
        sendPassToEMail: 'https://www.gridstkd.com.ua/server/sendPassToEMail.php',
        updateSportClubNameInAthleteProfile: 'https://www.gridstkd.com.ua/server/updateSportClubNameInAthleteProfile.php',
        deleteArchiveData: 'https://www.gridstkd.com.ua/server/deleteArchiveData.php',
        updateArchive: 'https://www.gridstkd.com.ua/server/updateArchive.php',
        foodEnrty: 'https://www.gridstkd.com.ua/server/foodEnrty.php',
        compititionChat: 'https://www.gridstkd.com.ua/server/compititionChat.php',
        getCompetitionById: 'https://www.gridstkd.com.ua/server/getCompetitionById.php',
        getAthletesForPoomsaeComp: 'https://www.gridstkd.com.ua/server/getAthletesForPoomsaeComp.php',
        addNewAthletesOnPoomsae: 'https://www.gridstkd.com.ua/server/addNewAthletesOnPoomsae.php',
        appoindCoachesOnCompetition:'https://www.gridstkd.com.ua/server/appoindCoachesOnCompetition.php',
        controlDeskResultToDB:'https://www.gridstkd.com.ua/server/controlDeskResultToDB.php',
        controlDeskDataToDB:'https://www.gridstkd.com.ua/server/controlDeskDataToDB.php',
        controlDeskResultRead:'https://www.gridstkd.com.ua/server/controlDeskResultRead.php',
        controlDeskDataRead:'https://www.gridstkd.com.ua/server/controlDeskDataRead.php',
        poomsaeCortResultRead:'https://www.gridstkd.com.ua/server/poomsaeCortResultRead.php',
        poomsaeCortResultToDB:'https://www.gridstkd.com.ua/server/poomsaeCortResultToDB.php',
        poomsaeReservCopyRead:'https://www.gridstkd.com.ua/server/poomsaeReservCopyRead.php',
        poomsaeReservCopyToDB:'https://www.gridstkd.com.ua/server/poomsaeReservCopyToDB.php',
        postLastChangesDate:'https://www.gridstkd.com.ua/server/postLastChangesDate.php',
       
    },
    imgAdress: {
        sportClubLogo: 'https://www.gridstkd.com.ua/public/img/logosSportclubs/',
        newCompetitionLogo: 'https://www.gridstkd.com.ua/public/img/logosTourn/',
        newCompetitionReglament: 'https://www.gridstkd.com.ua/public/img/reglametsTourn/',
        userProfilePhoto: 'https://www.gridstkd.com.ua/public/img/avatar/',
    },
     //////////////////////////////////////////////////////////////////////////////
    noAvatarPhoto: '/img/main/noPhotoAvatar.jpg',
    noLogoPhoto: '/img/main/noPhotoLogo.jpg',
    basketForComment: '/img/main/basketForComment.png',
    editHand: '/img/main/editHand.png',   
    boolean: '',
    mailId: [],// тут формується массив з id прочитаних повідомленн, які мозна використати для видалення з БД
    mail: [], //масив з таблицею mail з БД
    unreadMailInProfile: null,//кіл-ть непрочитаних повідомлень у профілі (виводиться на Аватарі)
    archive: [],
    userProfile: [{}],//профіль користувача
    athletes: [{}],
    sportClubs: [],
    tempUserProfile: [{}],
    tempAthleteProfile: [{}],//масив даних при редагуванні тренером профіля спортсмена
    tempNewCompetition: [],
    tempNewSportClub: [],
    rankTourn: [],
    poomse:[
        "https://www.youtube.com/embed/3WfW3RupcME?si=FkF68ryUUzi6Sk0B",
        "https://www.youtube.com/embed/IQo53bXY3No?si=Kj4CfE1h4vuohp_G",
        "https://www.youtube.com/embed/ivV9EeO9fi4?si=ujS2A5Cwlsp3A1xc",
        "https://www.youtube.com/embed/fWMMH535UyE?si=waFv44QtyHaQXVIa",
        "https://www.youtube.com/embed/WCtAbVfW65s?si=hPye1t46fhgS_p4E",
        "https://www.youtube.com/embed/oVIClmK1XrY?si=QH8XXJbx08wAsK_t",
        "https://www.youtube.com/embed/BIdTIhTOveI?si=xSyha1TmadoIyt-3",
        "https://www.youtube.com/embed/_ZljsDe5x4o?si=PgGJomI3ijEVGEoE",
        "https://www.youtube.com/embed/xCSX8jwbu-c?si=XDaQIZOXUkgYPHc6",
        "https://www.youtube.com/embed/z_RuM9cqmSM?si=LyXas6K-PGTGS4xb",
        "https://www.youtube.com/embed/Id1g7_JpJDA?si=BCVlXaJxXFhr1SOh",
        "https://www.youtube.com/embed/YJ8hZbn5MS4?si=7h0hZKLkXTpYIujT",
        "https://www.youtube.com/embed/Cqm2TGhyWTU?si=tQbl6NB-QrPfObeU",
        "https://www.youtube.com/embed/-1nEzUUW3gE?si=08UmJ_wLY2vy8X3K",
        "https://www.youtube.com/embed/yqDn81SMHUI?si=lFrbCVcPGPpqwm3g",
        "https://www.youtube.com/embed/Eu5_e6c_v2k?si=3rpkpJbuWWh4sAUg",
        "https://www.youtube.com/embed/Pe6AIL3nB3Y?si=SUkQwQrxqDpedlBh"
    ],
    youtube: [
        'https://www.youtube.com/embed/BNtMA5fR_jA?si=sSneuU1tSDbv4vy6',
        "https://www.youtube.com/embed/PBrag6KP-rU?si=NrgEJgPrn5TPIYkF",
        "https://www.youtube.com/embed/RKBduB9NP0o?si=JwL7_mWzM38qPPNS",
        "https://www.youtube.com/embed/OA_XUNf3V2w?si=roq3J1AM4-rYtyMc",
        "https://www.youtube.com/embed/gRvyQKrTDRE?si=j1gtycdZWc2ZcH_B",
        "https://www.youtube.com/embed/4RvxpxiyNkg?si=BG5Ur-MxIEn5XEER",
        "https://www.youtube.com/embed/tmo9ScQOTu4?si=TSL1OQDJ7pxmgJa7",
        "https://www.youtube.com/embed/7wly1DC1XQg?si=r22Sew5KeAtDPzrI",
        "https://www.youtube.com/embed/O-ulEhxNlqE?si=dlDU--jFlTkjBAX3",
        "https://www.youtube.com/embed/LlY5TiJqxHI?si=L2xGCOcn7mn88-GZ",
        "https://www.youtube.com/embed/fhY0t-E88Lw?si=2npAEoBMMPST8pt1",
        "https://www.youtube.com/embed/juc0xmS3TSY?si=szbDEAJUMWcbdPA1",
        "https://www.youtube.com/embed/9bChnhFtz8o?si=mRcU5N1xWU4t86QK",
        "https://www.youtube.com/embed/8i_D76j739w?si=jkUGF4Zocj92Res-",
        "https://www.youtube.com/embed/_BbvqmArldk?si=EpR2vK8xRfw4f1f5",
        "https://www.youtube.com/embed/ixfFRt-usa8?si=-2b4ZZFl-f4USohC",
        "https://www.youtube.com/embed/9Jkl1tAoDDA?si=BDMxX_rDjZ1b0K1w" 
    ],
    
    region: [ //Перелік обласних осередків ТКД
        'Івано-Франківський обласний осередок тхеквондо (ВТФ)',
        'Вінницький обласний осередок тхеквондо (ВТФ)',
        'Волинський обласний осередок тхеквондо (ВТФ)',
        'Дніпропетровський обласний осередок тхеквондо (ВТФ)',
        'Донецький обласний осередок тхеквондо (ВТФ)',
        'Житомирський обласний осередок тхеквондо (ВТФ)',
        'Закарпатський обласний осередок тхеквондо (ВТФ)',
        'Запорізький обласний осередок тхеквондо (ВТФ)',
        'Кропівницький обласний осередок тхеквондо (ВТФ)',
        'Київський обласний осередок тхеквондо (ВТФ)',
        'Луганський обласний осередок тхеквондо (ВТФ)',
        'Львівський обласний осередок тхеквондо (ВТФ)',
        'Миколайвський обласний осередок тхеквондо (ВТФ)',
        'Одеський обласний осередок тхеквондо (ВТФ)',
        'Полтавський обласний осередок тхеквондо (ВТФ)',
        'Рівненський обласний осередок тхеквондо (ВТФ)',
        'Сумський обласний осередок тхеквондо (ВТФ)',
        'Тернопільський обласний осередок тхеквондо (ВТФ)',
        'Харківський обласний осередок тхеквондо (ВТФ)',
        'Херсонський обласний осередок тхеквондо (ВТФ)',
        'Хмельницький обласний осередок тхеквондо (ВТФ)',
        'Черкаський обласний осередок тхеквондо (ВТФ)',
        'Чернівецький обласний осередок тхеквондо (ВТФ)',
        'Чернігівський обласний осередок тхеквондо (ВТФ)'
    ],

    dan: [ //Атестаційна кваліфікація спортсменів
        '10 куп',
        '9 куп',
        '8 куп',
        '7 куп',
        '6 куп',
        '5 куп',
        '4 куп',
        '3 куп',
        '2 куп',
        '1 куп',
        '1 дан',
        '2 дан',
        '3 дан',
        '4 дан',
        '5 дан',
        '6 дан',
        '7 дан',
        '8 дан',
        '9 дан',
        '10 дан'
    ],

    rank: [  //Спортивний розряд
        'Без розряду',
        '2 юнацький',
        '1 юнацький',
        '3 розряд',
        '2 розряд',
        '1 розряд',
        'КМС',
        'МС',
        'МСМК',
        'ЗМС'
    ],

    fst: [  //Фізкультурно-спортивне товариство
        'Не належить до ФСТ',
        'ЗСУ',
        'Колос',
        'Освіта',
        'Україна',
        'Спартак',
        'Динамо'
    ],

    render: () => { // Перезавантажує дерево сайту
        // Отримуємо кореневий DOM-елемент, куди будемо вставляти React-дерево
        const rootElement = document.getElementById('root');
        // Отримуємо React-кореневий компонент вашого додатку
        // Замість `App` вкажіть свій кореневий компонент
       
          const rootComponent = <Registration />;
        // Очищаємо DOM-елемент, щоб видалити попереднє React-дерево
        ReactDOM.unmountComponentAtNode(rootElement);
        // Рендеримо нове React-дерево
        ReactDOM.render(rootComponent, rootElement);
    },

    formatDate: (inputDate) => { //змінення формату виводу дати2
        // Перевірка, чи `inputDate` не є `null`
        if (inputDate === null || inputDate === undefined) {
            return null;
        }

        const dateParts = inputDate.split('-'); // Розділити рядок на частини
        if (dateParts.length === 3) {
            // Перевірити, чи вхідний рядок містить 3 частини (рік, місяць, день)
            const day = dateParts[2];
            const month = dateParts[1];
            const year = dateParts[0];
            return `${day}.${month}.${year}`; // Зібрати частини разом в бажаному форматі
        }
        return inputDate; // Якщо вхідний рядок не містить 3 частини, повернути його без змін
    },

    dateForMessage: () => {//Поточна дата. використовується в повідомленнях
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;


        return formattedDate
    },
    lastChangesDateFormat: () => {//Поточна дата. використовується в повідомленнях
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;


        return formattedDate
    },
    age: (birthday) => {
        const today = new Date(); // поточна дата
        const birthDate = new Date(birthday); // дата народження
    
        let age = today.getFullYear() - birthDate.getFullYear(); // початковий розрахунок віку
        const monthDiff = today.getMonth() - birthDate.getMonth(); // різниця в місяцях
        const dayDiff = today.getDate() - birthDate.getDate(); // різниця в днях
    
        // Перевіряємо, чи вже настав день народження цього року
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--; // якщо день народження ще не настав, зменшуємо вік на 1
        }
    
        return age; // повертаємо точний вік
    },
    ratingQuery: (id, heightCategories,olimpicAdults, olimpicJuniors) => { //Отримуємо рейтинг спортсмена 
        const result = []//повергає: місце в загальному рейтингу, категорію, місце в рейтингу категорії

        // Сортуємо атлетів за рейтингом

        const athletes = JSON.parse(JSON.stringify(state.athletes))
        athletes.sort((a, b) => b.rating - a.rating);

        // Знаходимо індекс атлета у відсортованому масиві
        const absoluteRatingIndex = athletes.findIndex((athlete) => athlete.id == id);
        result.push(absoluteRatingIndex + 1);

        // Сортуємо атлетів за категоріями
        const sortedAthletesByCategories = state.tournConstructor.sortAthletesByCategories(athletes, heightCategories,olimpicAdults, olimpicJuniors);

        // Перебираємо категорії
        for (const [category, athletes] of Object.entries(sortedAthletesByCategories)) {
            // Знаходимо категорію, в якій міститься атлет з вказаним id
            if (athletes.some((athlete) => athlete.id == id)) {
                // Сортуємо атлетів цієї категорії за полем rating
                athletes.sort((a, b) => b.rating - a.rating);

                // Знаходимо індекс атлета в відсортованому масиві
                const ratingInCategoryIndex = athletes.findIndex((athlete) => athlete.id == id);

                result.push(category);
                result.push(ratingInCategoryIndex + 1);

                break; // Зупиняємо цикл, якщо категорія знайдена
            }
        }

        return result;
    },

    tournConstructor: { //Данні для створення турнірів
        gridsMaker: (sortedCategories, athletesArray, competition, numberOfCort = null) => {

            const categories = state.tournConstructor.categoryOrder;

            const grids = [];
      

            for (const category of categories) {
                const athletes = sortedCategories[category];

                // Пропускаємо порожні категорії
                if (!athletes || athletes.length === 0) {
                    continue;
                }

                // Рахуємо кількість спортсменів
                const numberOfAthletes = athletes.length;

                // Якщо в категорії один спортсмен
                if (numberOfAthletes == 1) {
                    // Перевіряємо умови для перенесення спортсмена
                    if (category.endsWith("Plus") || category.endsWith("Over") || category.endsWith("Minus") || !sortedCategories[categories[categories.indexOf(category) + 1]]) {
           
                        // Викликаємо компоненту <GridN/>, для одного спортсмена
                        const GridComponent = 2;

                        // Додавання компоненти до масиву
                        grids.push({
                            numberOfAthletes: 1,
                            athletes: athletes,
                            category: category
                        })

                        state.gridsReport.push(
                            `<p>В категорії 
        <a href="#${category}">
        <span>${state.weightCatUkr(category)} </span>
        </a>
        знаходиться один спортсмен, якого неможливо перевести в іншу категорію!</p>`);
                    } else {
                        // Переносимо спортсмена в наступну категорію
                        const nextCategory = categories[categories.indexOf(category) + 1];
                        sortedCategories[nextCategory].push(...athletes);
                    }
                }
                // Якщо в категорії більше одного спортсмена
                else {
                    // Викликаємо компоненту <GridN/>, де N - кількість спортсменів
                    const GridComponent = `Grid${numberOfAthletes}`;

                    // Додавання компоненти до масиву
                    grids.push({
                        numberOfAthletes: numberOfAthletes,
                        athletes: athletes,
                        category: category
                    })

                }
            }

            state.gridsReport.unshift(
                state.gridsReport.length === 0 ? '<h4>Жеребкування відбулось без зауважень.</h4>' : '<h4>Зауваження при жеребкуванні: </h4>'
            )
            const result= state.tournConstructor.cortsAndBattleNumber(grids,athletesArray,competition,false,numberOfCort)
            return result
        },

        cortsAndBattleNumber:(grids,athletesArray,competition,poomse=false,numberOfCort)=>{

            /////////////////////////////////////////////////////////////////////////////////
            const alongInGrid=grids.filter((item) => (item.numberOfAthletes == 1)) //кількість категорій з самотніми спортсменами
         
            if (!competition.corts || competition.corts == 0) { competition.corts = 1 }
             //кількість кортів на змаганнях  
            const corts = parseInt(competition.corts);

            //формула визначення кількості поєдинків на корті
             let battlesOnCort 
             if (poomse){
                let athletesCount=0
                grids.forEach((item)=>{athletesCount=athletesCount+item.athletes.length})
                battlesOnCort = Math.ceil((athletesCount - grids.length + alongInGrid.length) / corts);
             }else{
             battlesOnCort = Math.ceil((athletesArray.length - grids.length + alongInGrid.length) / corts);
            }
           
            let sortedGridsWithCorts;
            let cort = 1; let battlesFull = 0; //поєдинкі на корті

            ////////////// якщо вказаний корт для категорії
            
            if (numberOfCort) {//Ручні сітки для Керугі
                sortedGridsWithCorts = grids.map((grid) => {
                    grid.numberOfAthletes = parseInt(grid.numberOfAthletes)
                    let battleInGrid  //поєдинкі в категорії

                    if (grid.numberOfAthletes == 0) { return }
                    if (grid.numberOfAthletes == 1) { battleInGrid = 1 }
                    if (grid.numberOfAthletes > 1) { battleInGrid = grid.numberOfAthletes - 1 }


                    battlesFull = battleInGrid + battlesFull
                    return ({ ...grid, cort: numberOfCort, battleNumbers: [] })

                })
            } else { // не вказаний корт для категорії
                let gridsWithCorts =[]
if(poomse){//Сітки для Пхумсе
    const classA = grids.filter((item) => item.category.endsWith('ClassA'));
    const classB = grids.filter((item) => item.category.endsWith('ClassB'));
if(classB.length>0){
    let classAArray=[];let classBArray=[];
    cortsMaker(classA,classAArray)
     fromCortToCort(classAArray)
      cortsMaker(classB,classBArray)
    fromCortToCort(classBArray)
    classAArray.forEach((item)=>{gridsWithCorts.push(item)})
     classBArray.forEach((item)=>{gridsWithCorts.push(item)})
}else{
    cortsMaker(classA)
    fromCortToCort(gridsWithCorts)
}
}else{//автоматичні сітки для Кегугі
                cortsMaker(grids)
               fromCortToCort(gridsWithCorts)
}


    function cortsMaker(grids,templateArray=null){
const test=Array.isArray(templateArray)?true:false
    grids.forEach((grid) => {
grid.numberOfAthletes = parseInt(grid.numberOfAthletes);
let battleInGrid;  // поєдинкі в категорії

if (grid.numberOfAthletes == 0) { return; }
if (grid.numberOfAthletes == 1) { battleInGrid = 1; }
if (grid.numberOfAthletes > 1) { battleInGrid = grid.numberOfAthletes - 1; }

if ((battleInGrid + battlesFull) <= battlesOnCort && cort < corts) {
 battlesFull = battleInGrid + battlesFull;
    if(test){templateArray.push({ ...grid, cort: cort, battleNumbers: [] });}
    else{
    gridsWithCorts.push({ ...grid, cort: cort, battleNumbers: [] });
    }
} else if ((battleInGrid + battlesFull) > battlesOnCort && cort < corts) {
 battlesFull = battleInGrid;
 cort++;
    if(test){templateArray.push({ ...grid, cort: cort, battleNumbers: [] });}
    else{
    gridsWithCorts.push({ ...grid, cort: cort, battleNumbers: [] });
    }
} else if (cort == corts) {
 battlesFull = battleInGrid + battlesFull;
    if(test){templateArray.push({ ...grid, cort: cort, battleNumbers: [] });}
    else{
    gridsWithCorts.push({ ...grid, cort: cort, battleNumbers: [] });
    }
}
});
 } 
// переносимо категорії по кортах, заповнюючі порожнечі 
                function fromCortToCort(cortArray) {
                    for (let i = 1; i <= corts; i++) {
                        let cort = 1
                        let battleCount = 0
                        //визначаємо кількість поєдинків на корті
                        const cortData = cortArray.filter((item) => item.cort == cort)
                        cortData.forEach((item) => {
                            battleCount = item.athletes.length === 1 ? battleCount + 1 : battleCount + (item.athletes.length - 1);
                        });


                        if (battleCount < battlesOnCort) {
                            //шукаємо переповнений корт

                            let battleCount2 = 0
                            for (let cort2 = cort + 1; cort2 <= corts; cort2++) {
                                const cortData2 = cortArray.filter((item) => item.cort == cort2)
                                cortData2.forEach((item) => {
                                    battleCount2 = item.athletes.length === 1 ? battleCount2 + 1 : battleCount2 + (item.athletes.length - 1);

                                });

                                if (battleCount2 > battlesOnCort) {
                                    cortData2.forEach((item) => {
                                        let battleCount3 = 0
                                        battleCount3 = item.athletes.length === 1 ? battleCount3 + 1 : battleCount3 + (item.athletes.length - 1);

                                        if (battleCount2 > battlesOnCort && (battleCount3 + battleCount) < battlesOnCort) {
                                            item.cort = cort;
                                            battleCount = battleCount + battleCount3
                                        }
                                    })
                                }

                            }//кінець внутрішнього for 
                        }//кінець умови
                    }//кінець зовнішнього for 
                }
                

                sortedGridsWithCorts = [...gridsWithCorts].sort((a, b) => {
                    if (a.cort !== b.cort) {
                        return a.cort - b.cort; // Спочатку сортуємо за `cort`
                    } else {
                        return b.numberOfAthletes - a.numberOfAthletes; // Потім сортуємо за `numberOfAthletes`
                    }
                });

            }
            function spelBattleNumber(grids) {
                let battleCount = 1
                // умова >= для сіток, що заходять з вищого кола, для решти ===
                const _1_32Finale = grids.map((category) => {
                    if (category.athletes.length >= 39) {
                        for (let i = 1; i <= 7; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 38) {
                        for (let i = 1; i <= 6; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 37) {
                        for (let i = 1; i <= 5; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 36) {
                        for (let i = 1; i <= 4; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 35) {
                        for (let i = 1; i <= 3; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 34) {
                        for (let i = 1; i <= 2; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 33) {
                        category.battleNumbers.push(battleCount); battleCount++;
                    }


                    return category
                })
                const _1_16Finale = _1_32Finale.map((category) => {
                    if (category.athletes.length >= 32) {
                        for (let i = 1; i <= 16; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 31) {
                        for (let i = 1; i <= 15; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 30) {
                        for (let i = 1; i <= 14; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 29) {
                        for (let i = 1; i <= 13; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 28) {
                        for (let i = 1; i <= 12; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 27) {
                        for (let i = 1; i <= 11; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 26) {
                        for (let i = 1; i <= 10; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 25) {
                        for (let i = 1; i <= 9; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 24) {
                        for (let i = 1; i <= 8; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 23) {
                        for (let i = 1; i <= 7; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 22) {
                        for (let i = 1; i <= 6; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 21) {
                        for (let i = 1; i <= 5; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 20) {
                        for (let i = 1; i <= 4; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 19) {
                        for (let i = 1; i <= 3; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 18) {
                        for (let i = 1; i <= 2; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 17) {
                        category.battleNumbers.push(battleCount); battleCount++;
                    }
                    return category
                })

                const _1_8Finale = _1_16Finale.map((category) => {

                    if (category.athletes.length >= 16) {
                        for (let i = 1; i <= 8; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 15) {
                        for (let i = 1; i <= 7; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 14) {
                        for (let i = 1; i <= 6; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 13) {
                        for (let i = 1; i <= 5; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 12) {
                        for (let i = 1; i <= 4; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 11) {
                        for (let i = 1; i <= 3; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 10) {
                        for (let i = 1; i <= 2; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 9) {
                        category.battleNumbers.push(battleCount); battleCount++;
                    }
                    return category
                })

                const _1_4Finale = _1_8Finale.map((category) => {

                    if (category.athletes.length >= 8) {
                        for (let i = 1; i <= 4; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 7) {
                        for (let i = 1; i <= 3; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 6) {
                        for (let i = 1; i <= 2; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 5) {
                        category.battleNumbers.push(battleCount); battleCount++;
                    }
                    return category
                })
                const _1_2Finale = _1_4Finale.map((category) => {
                    if (category.athletes.length >= 5) {
                        for (let i = 1; i <= 2; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 4) {
                        for (let i = 1; i <= 2; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    if (category.athletes.length === 3) {
                        for (let i = 1; i <= 1; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                    }
                    return category
                })
                const Finale = _1_2Finale.map((category) => {
                    category.battleNumbers.push(battleCount); battleCount++;
                    return category
                })
            }

            for (let i = 1; i <= corts; i++) {
                const filteredGrids = sortedGridsWithCorts.filter((item) => (item.cort === i))

                spelBattleNumber(filteredGrids)
            }

            state.competitionResult = []

            //  setGrids(sortedGridsWithCorts)
            if (!numberOfCort) { state.grids = sortedGridsWithCorts }


            /////////////////////////////////////////////////////////////////////////////////
          
            return sortedGridsWithCorts;

        },
       
        ageCat: {//Вікові категорії, вікористовуються при перевірках спортсменів при заявці на турнир
            jrChildren: {from: 6, to: 7},
            children: { from: 8, to: 9 },
            jrYounkers: { from: 10, to: 11 },
            younkers: { from: 12, to: 13 },
            cadets: { from: 14, to: 15 },
            juniors: { from: 16, to: 17 },
            adults: { from: 18, to: 39 },
            veterans: { from: 40, to: 150 },
        },
        categoryOrder: [//послідовність переборкі ключів
           'jrChildrenMale18', 'jrChildrenMale20', 'jrChildrenMale22', 'jrChildrenMale24', 'jrChildrenMale26', 'jrChildrenMale28', 'jrChildrenMale30', 'jrChildrenMale35', 'jrChildrenMale35Plus',
           'jrChildrenFemale18', 'jrChildrenFemale20', 'jrChildrenFemale22', 'jrChildrenFemale24', 'jrChildrenFemale26', 'jrChildrenFemale28', 'jrChildrenFemale30', 'jrChildrenFemale35', 'jrChildrenFemale35Plus',
           'childrenMale20', 'childrenMale22', 'childrenMale24', 'childrenMale26', 'childrenMale28', 'childrenMale30', 'childrenMale35', 'childrenMale40', 'childrenMale40Plus',
           'childrenFemale18', 'childrenFemale20', 'childrenFemale22', 'childrenFemale24', 'childrenFemale26', 'childrenFemale28', 'childrenFemale30', 'childrenFemale35', 'childrenFemale35Plus',
           'jrYounkersMale24', 'jrYounkersMale26', 'jrYounkersMale28', 'jrYounkersMale30', 'jrYounkersMale35', 'jrYounkersMale40', 'jrYounkersMale45', 'jrYounkersMale50', 'jrYounkersMale50Plus',
           'jrYounkersFemale24', 'jrYounkersFemale26', 'jrYounkersFemale28', 'jrYounkersFemale30', 'jrYounkersFemale35', 'jrYounkersFemale40', 'jrYounkersFemale45', 'jrYounkersFemale50', 'jrYounkersFemale50Plus',
           'younkersMale30', 'younkersMale35', 'younkersMale40', 'younkersMale45', 'younkersMale50', 'younkersMale55', 'younkersMale60', 'younkersMale65', 'younkersMale65Plus',
           'younkersFemale30', 'younkersFemale35', 'younkersFemale40', 'younkersFemale45', 'younkersFemale50', 'younkersFemale55', 'younkersFemale60', 'younkersFemale65', 'younkersFemale65Plus',
           'cadetsMale35', 'cadetsMale40', 'cadetsMale45', 'cadetsMale50', 'cadetsMale55', 'cadetsMale60', 'cadetsMale65', 'cadetsMale70', 'cadetsMale75', 'cadetsMale75Plus',
           'cadetsFemale35', 'cadetsFemale40', 'cadetsFemale45', 'cadetsFemale50', 'cadetsFemale55', 'cadetsFemale60', 'cadetsFemale65', 'cadetsFemale70', 'cadetsFemale75', 'cadetsFemale75Plus',
           'juniorsMale45', 'juniorsMale50', 'juniorsMale55', 'juniorsMale60', 'juniorsMale65', 'juniorsMale70', 'juniorsMale75', 'juniorsMale80', 'juniorsMale85', 'juniorsMale85Plus',
           'juniorsFemale40', 'juniorsFemale45', 'juniorsFemale50', 'juniorsFemale55', 'juniorsFemale60', 'juniorsFemale65', 'juniorsFemale70', 'juniorsFemale75', 'juniorsFemale80', 'juniorsFemale80Plus',
           'adultsMale55', 'adultsMale60', 'adultsMale65', 'adultsMale70', 'adultsMale75', 'adultsMale80', 'adultsMale85', 'adultsMale90', 'adultsMale100', 'adultsMale100Plus',
           'adultsFemale45', 'adultsFemale50', 'adultsFemale55', 'adultsFemale60', 'adultsFemale65', 'adultsFemale70', 'adultsFemale75', 'adultsFemale80', 'adultsFemale85', 'adultsFemale85Plus',
           'veteransMale55', 'veteransMale60', 'veteransMale65', 'veteransMale70', 'veteransMale75', 'veteransMale80', 'veteransMale85', 'veteransMale90', 'veteransMale100', 'veteransMale100Plus',
           'veteransFemale45', 'veteransFemale50', 'veteransFemale55', 'veteransFemale60', 'veteransFemale65', 'veteransFemale70', 'veteransFemale75', 'veteransFemale80', 'veteransFemale85','veteransFemale85Plus'
        ],
        categoryOrderPoomsae:[
            'jrYounkersMaleTraditionalPersonaleClassA','jrYounkersFemaleTraditionalPersonaleClassA','younkersMaleTraditionalPersonaleClassA','younkersFemaleTraditionalPersonaleClassA','cadetsMaleTraditionalPersonaleClassA','cadetsFemaleTraditionalPersonaleClassA','juniorsMaleTraditionalPersonaleClassA','juniorsFemaleTraditionalPersonaleClassA','adultsMale30TraditionalPersonaleClassA','adultsFemale30TraditionalPersonaleClassA','adultsMale40TraditionalPersonaleClassA','adultsFemale40TraditionalPersonaleClassA','adultsMale50TraditionalPersonaleClassA','adultsFemale50TraditionalPersonaleClassA','adultsMale60TraditionalPersonaleClassA','adultsFemale60TraditionalPersonaleClassA','adultsMale65TraditionalPersonaleClassA','adultsFemale65TraditionalPersonaleClassA','adultsMale65PlusTraditionalPersonaleClassA','adultsFemale65PlusTraditionalPersonaleClassA',
            'jrYounkersTraditionalCoupleClassA','younkersTraditionalCoupleClassA','cadetsTraditionalCoupleClassA','juniorsTraditionalCoupleClassA','adults30TraditionalCoupleClassA','adults40TraditionalCoupleClassA','adults50TraditionalCoupleClassA','adults60TraditionalCoupleClassA','adults60PlusTraditionalCoupleClassA',
            'jrYounkersMaleTraditionalTeamClassA','jrYounkersFemaleTraditionalTeamClassA','younkersMaleTraditionalTeamClassA','younkersFemaleTraditionalTeamClassA','cadetsMaleTraditionalTeamClassA','cadetsFemaleTraditionalTeamClassA','juniorsMaleTraditionalTeamClassA','juniorsFemaleTraditionalTeamClassA','adultsMale30TraditionalTeamClassA','adultsFemale30TraditionalTeamClassA','adultsMale40TraditionalTeamClassA','adultsFemale40TraditionalTeamClassA','adultsMale50TraditionalTeamClassA','adultsFemale50TraditionalTeamClassA','adultsMale60TraditionalTeamClassA','adultsFemale60TraditionalTeamClassA','adultsMale60PlusTraditionalTeamClassA','adultsFemale60PlusTraditionalTeamClassA',
            
            'freestylePersonaleMale17ClassA','freestylePersonaleFemale17ClassA','freestylePersonaleMale17PlusClassA','freestylePersonaleFemale17PlusClassA',
            'freestyleCouple17ClassA','freestyleCouple17PlusClassA',
            'freestyleTeamClassA',

            'mixPoomsaePersonaleMaleClassA','mixPoomsaePersonaleFemaleClassA',
            'mixPoomsaeCoupleClassA',
            'mixPoomsaeTeamMaleClassA','mixPoomsaeTeamFemaleClassA',
            'mixPoomsaeMixedTeamClassA',

            'jrYounkersMaleTraditionalPersonaleClassB','jrYounkersFemaleTraditionalPersonaleClassB','younkersMaleTraditionalPersonaleClassB','younkersFemaleTraditionalPersonaleClassB','cadetsMaleTraditionalPersonaleClassB','cadetsFemaleTraditionalPersonaleClassB','juniorsMaleTraditionalPersonaleClassB','juniorsFemaleTraditionalPersonaleClassB','adultsMale30TraditionalPersonaleClassB','adultsFemale30TraditionalPersonaleClassB','adultsMale40TraditionalPersonaleClassB','adultsFemale40TraditionalPersonaleClassB','adultsMale50TraditionalPersonaleClassB','adultsFemale50TraditionalPersonaleClassB','adultsMale60TraditionalPersonaleClassB','adultsFemale60TraditionalPersonaleClassB','adultsMale65TraditionalPersonaleClassB','adultsFemale65TraditionalPersonaleClassB','adultsMale65PlusTraditionalPersonaleClassB','adultsFemale65PlusTraditionalPersonaleClassB',
            'jrYounkersTraditionalCoupleClassB','younkersTraditionalCoupleClassB','cadetsTraditionalCoupleClassB','juniorsTraditionalCoupleClassB','adults30TraditionalCoupleClassB','adults40TraditionalCoupleClassB','adults50TraditionalCoupleClassB','adults60TraditionalCoupleClassB','adults60PlusTraditionalCoupleClassB',
            'jrYounkersMaleTraditionalTeamClassB','jrYounkersFemaleTraditionalTeamClassB','younkersMaleTraditionalTeamClassB','younkersFemaleTraditionalTeamClassB','cadetsMaleTraditionalTeamClassB','cadetsFemaleTraditionalTeamClassB','juniorsMaleTraditionalTeamClassB','juniorsFemaleTraditionalTeamClassB','adultsMale30TraditionalTeamClassB','adultsFemale30TraditionalTeamClassB','adultsMale40TraditionalTeamClassB','adultsFemale40TraditionalTeamClassB','adultsMale50TraditionalTeamClassB','adultsFemale50TraditionalTeamClassB','adultsMale60TraditionalTeamClassB','adultsFemale60TraditionalTeamClassB','adultsMale60PlusTraditionalTeamClassB','adultsFemale60PlusTraditionalTeamClassB',
           
            'freestylePersonaleMale17ClassB','freestylePersonaleFemale17ClassB','freestylePersonaleMale17PlusClassB','freestylePersonaleFemale17PlusClassB',
            'freestyleCouple17ClassB','freestyleCouple17PlusClassB',
            'freestyleTeamClassB',
            
            'mixPoomsaePersonaleMaleClassB','mixPoomsaePersonaleFemaleClassB',
            'mixPoomsaeCoupleClassB',
            'mixPoomsaeTeamMaleClassB','mixPoomsaeTeamFemaleClassB',
            'mixPoomsaeMixedTeamClassB'        
        ],
        sortAthletesInGrid: (athletes) => { // формуємо сітки
            // if (Array.isArray(athletes[0])){return athletes}
            athletes = athletes.map((athlete) => {//вносимо назву команди в профіль

                return athlete;
            });

            // створюємо заготовку для масиву об'єктів
            const count = athletes.length;
            const result = Array.from({ length: count % 2 === 0 ? count / 2 : (count + 1) / 2 }, () => ({ 1: null, 2: null }));
            //сортуємо атлетів за рейтигном 
            athletes.sort((a, b) => b.rating - a.rating);
            //ставимо самого сильного спортсмена в початок массиву

            result[0][1] = athletes[0];
            //ставимо його суперника в кінець масиву
            if (Number.isInteger(count / 2)) {//умова для парної кількості спортсменів
                result[count / 2 - 1][2] = athletes[1];
            } else if (count > 1) {//умова для непарної кількості спортсменів
                result[Math.ceil(count / 2) - 1][1] = athletes[1];
            }
            athletes.splice(0, 2);//видаляємо з вхідного масиву розподілених спортсменів


            const teams = state.tournConstructor.sortAthletesByTeam(athletes)
            ////////////////////////////////////////////////////////////////////////////////////

            let remainingAthletes = Object.entries(teams)
                .map(([team, athletes]) => ({ team, athletes }))
                .sort((a, b) => b.athletes.length - a.athletes.length)
                .flatMap(({ team, athletes }) => athletes.map(athlete => ({ ...athlete, team })));
              
            for (let i = 0; count % 2 === 0 ? i < result.length : i < result.length - 1; i++) {
                //заповнюємо перші об'єкти
                if (result[i][1] === null && remainingAthletes.length > 0) {
                    //вилучаємо перший елемент масиву та передаєм в result	
                    result[i][1] = remainingAthletes.shift();
                    //заповнюємо другі об'єкти
                } else if (result[i][1] !== null && result[i][2] === null && remainingAthletes.length > 0 && i !== result.length - 1) {
                    //сортуємо по командах

                    let differentTeamAthlete
                    /////////////////////////////////////////////
                    try {
                        remainingAthletes.filter(athlete => {
                            // Створюємо об'єкт для відстеження кількості спортсменів з кожної команди
                            const teamCount = {};

                            // Витягаємо тільки тих спортсменів, від яких є більше одного в команді
                            const otherAthletesInSameTeam = remainingAthletes.filter(otherAthlete => {
                                // Якщо команда вже зустрічається, збільшуємо лічильник і повертаємо true
                                if (teamCount[otherAthlete.team]) {
                                    teamCount[otherAthlete.team]++;
                                    return true;
                                } else {
                                    // Якщо команда ще не зустрічається, створюємо запис з лічильником 1 і повертаємо false
                                    teamCount[otherAthlete.team] = 1;
                                    return false;
                                }
                            });

                            // Вибираємо тих, які не з команди в результі
                            const notInResultTeam = otherAthletesInSameTeam.filter(
                                otherAthlete => otherAthlete.team !== result[i][1].team
                            );

                            differentTeamAthlete = notInResultTeam[0]

                        });


                    } catch (error) { differentTeamAthlete = remainingAthletes.find(athlete => athlete.team !== result[i][1].team); }

                    if (differentTeamAthlete) {
                        result[i][2] = differentTeamAthlete;
                        remainingAthletes = remainingAthletes.filter(athlete => athlete !== differentTeamAthlete);
                    }
                }
            }
            //заносимо тих, кого невдалось відсортувати
            for (let i = 0; count % 2 === 0 ? i < result.length : i < result.length - 1; i++) {
                if (result[i][2] === null && remainingAthletes.length > 0) {
                    result[i][2] = remainingAthletes.shift()
                }
            }

            // перевіряємо вихідний масив та переносимо в різні об'єкти спортсменів з однієї команди
            //переносимо другий об'ект

            function areTeamsEqual(obj1, obj2) {
                return obj1 && obj2 && obj1.team === obj2.team;
            }

            // Переносимо об'єкти з однаковими командами між парними елементами
            for (let i = 0; i < result.length - 1; i++) {
                for (let j = i + 1; j < result.length - 1; j++) {
                    // Перевірка, чи мають обидві ячейки значення `team` та чи вони різні
                    if (areTeamsEqual(result[i][1], result[j][1]) && !areTeamsEqual(result[i][2], result[j][2])) {
                        // Переносимо об'єкти, якщо команди однакові і не має співпадінь вже в перенесених об'єктах
                        [result[i][2], result[j][2]] = [result[j][2], result[i][2]];
                    }
                }
            }



            return result
        },

        entryAthletesFind: (athletes) => {//отримуємо з state.athletes масив заявлених на змагання спортсменів

            if (athletes) {
                try { athletes = JSON.parse(athletes) }
                catch (error) { }
                if (athletes.length > 0) {
                    //отримуємо з БД дані спортсменів що заявлені на турнир    
                    let athletesIds

                    try { athletesIds = JSON.parse(athletes).map(athlete => athlete.id) }
                    catch (error) { athletesIds = athletes.map(athlete => athlete.id) }
                    // видаляємо з массиву атлетів зайві поля

                    const entryAthletesData = state.athletes.filter(athlete => athletesIds.includes(athlete.id))


                    const athletesData = entryAthletesData.map((a) => {
                        const result = {}
                        result.id = a.id; result.secondName = a.secondName; result.personName = a.personName; result.fatherName = a.fatherName; result.photo = a.photo;
                        result.birthday = a.birthday; result.sex = a.sex; result.weiht = a.weiht; result.height = a.height; result.rating = a.rating;
                        result.coach = a.coach; result.coach2 = a.coach2; result.sportClub = a.sportClub; result.region = a.region; result.insuranceDate = a.insuranceDate;
                        result.medicalCertificateNum = a.medicalCertificateNum; result.medicalCertificateDate = a.medicalCertificateDate;
                        result.dan = a.dan; result.rank = a.rank; result.fst = a.fst; result.insuranceComp = a.insuranceComp; result.insuranceNum = a.insuranceNum;
                        result.battles = a.battles; result.victory = a.victory; result.battlesOnComp = a.battlesOnComp; result.victoryOnComp = a.victoryOnComp;
                        result.myCompetition = a.myCompetition;
                        return result
                    })

                    const teamAddField = athletesData.map((athlete) => ({// додаємо до профіля спортсмена данні від якої команди він заявлений
                        ...athlete,
                        team: athletes.find((a) => a.id == athlete.id).team,
                    }));

                    const entryAthletes = teamAddField.map((athlete) => {//вносимо назву команди в профіль



                        // перевірка, чи athlete.team не є undefined
                        if (athlete.team !== undefined) {
                            if (athlete.team === 'region') {
                                athlete.team = state.regionShort(athlete.region);
                            }
                            if (athlete.team === 'sportClub') {
                                athlete.team = athlete.sportClub;
                            }
                        } else {
                            return athlete;
                        }


                        return athlete;
                    });


                    return entryAthletes
                }
            }

        },

        sortAthletesByCategories: (athletes, heightCategories, olimpicAdults, olimpicJuniors) => {//Сортуємо спортсменів по категоріям

            if (athletes) {
                try { athletes = JSON.parse(athletes) }
                catch (error) { }


                const entryAthletes = state.tournConstructor.entryAthletesFind(athletes)

                if (!Number.isInteger(heightCategories)) { parseInt(heightCategories, 10); }
                const result = {}

                // молодші ДІТИ: хлопці -18кг
                const jrChildrenMale18 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) <= 18)
                })
                if (jrChildrenMale18.length > 0) result.jrChildrenMale18 = jrChildrenMale18;
                // молодші ДІТИ: хлопці -20кг
                const jrChildrenMale20 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 18 && parseFloat(at.weiht) <= 20)
                })
                if (jrChildrenMale20.length > 0) result.jrChildrenMale20 = jrChildrenMale20
                // молодші ДІТИ: хлопці -22кг
                const jrChildrenMale22 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 20 && parseFloat(at.weiht) <= 22)
                })
                if (jrChildrenMale22.length > 0) result.jrChildrenMale22 = jrChildrenMale22
                // молодші ДІТИ: хлопці -24кг
                const jrChildrenMale24 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 22 && parseFloat(at.weiht) <= 24)
                })
                if (jrChildrenMale24.length > 0) result.jrChildrenMale24 = jrChildrenMale24
                // молодші ДІТИ: хлопці -26кг
                const jrChildrenMale26 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 24 && parseFloat(at.weiht) <= 26)
                })
                if (jrChildrenMale26.length > 0) result.jrChildrenMale26 = jrChildrenMale26
                // молодші ДІТИ: хлопці -28кг
                const jrChildrenMale28 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 26 && parseFloat(at.weiht) <= 28)
                })
                if (jrChildrenMale28.length > 0) result.jrChildrenMale28 = jrChildrenMale28
                // молодші ДІТИ: хлопці -30кг
                const jrChildrenMale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 28 && parseFloat(at.weiht) <= 30)
                })
                if (jrChildrenMale30.length > 0) result.jrChildrenMale30 = jrChildrenMale30
                // молодші ДІТИ: хлопці -35кг
                const jrChildrenMale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (jrChildrenMale35.length > 0) result.jrChildrenMale35 = jrChildrenMale35

                // молодші ДІТИ: хлопці +35кг
                const jrChildrenMale35Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 35)
                })
                if (jrChildrenMale35Plus.length > 0) result.jrChildrenMale35Plus = jrChildrenMale35Plus


                // молодші ДІТИ: дівчата -18кг
                const jrChildrenFemale18 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) <= 18)
                })
                if (jrChildrenFemale18.length > 0) result.jrChildrenFemale18 = jrChildrenFemale18
                // молодші ДІТИ: дівчата -20кг
                const jrChildrenFemale20 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 18 && parseFloat(at.weiht) <= 20)
                })
                if (jrChildrenFemale20.length > 0) result.jrChildrenFemale20 = jrChildrenFemale20
                // молодші ДІТИ: дівчата -22кг
                const jrChildrenFemale22 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 20 && parseFloat(at.weiht) <= 22)
                })
                if (jrChildrenFemale22.length > 0) result.jrChildrenFemale22 = jrChildrenFemale22
                // молодші ДІТИ: дівчата -24кг
                const jrChildrenFemale24 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 22 && parseFloat(at.weiht) <= 24)
                })
                if (jrChildrenFemale24.length > 0) result.jrChildrenFemale24 = jrChildrenFemale24
                // молодші ДІТИ: дівчата -26кг
                const jrChildrenFemale26 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 24 && parseFloat(at.weiht) <= 26)
                })
                if (jrChildrenFemale26.length > 0) result.jrChildrenFemale26 = jrChildrenFemale26
                // молодші ДІТИ: дівчата -28кг
                const jrChildrenFemale28 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 26 && parseFloat(at.weiht) <= 28)
                })
                if (jrChildrenFemale28.length > 0) result.jrChildrenFemale28 = jrChildrenFemale28
                // молодші ДІТИ: дівчата -30кг
                const jrChildrenFemale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 28 && parseFloat(at.weiht) <= 30)
                })
                if (jrChildrenFemale30.length > 0) result.jrChildrenFemale30 = jrChildrenFemale30               

                 // молодші ДІТИ: дівчата -35кг
                 const jrChildrenFemale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (jrChildrenFemale35.length > 0) result.jrChildrenFemale35 = jrChildrenFemale35

                // молодші ДІТИ: дівчата +35кг
                const jrChildrenFemale35Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7 && parseFloat(at.weiht) > 35)
                })
                if (jrChildrenFemale35Plus.length > 0) result.jrChildrenFemale35Plus = jrChildrenFemale35Plus

            //////////////////////////////////////////////////////////////////////////////////////

                // ДІТИ: хлопці -20кг
                const childrenMale20 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9  && parseFloat(at.weiht) <= 20)
                })
                if (childrenMale20.length > 0) result.childrenMale20 = childrenMale20
                // ДІТИ: хлопці -22кг
                const childrenMale22 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 20 && parseFloat(at.weiht) <= 22)
                })
                if (childrenMale22.length > 0) result.childrenMale22 = childrenMale22
                // ДІТИ: хлопці -24кг
                const childrenMale24 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 22 && parseFloat(at.weiht) <= 24)
                })
                if (childrenMale24.length > 0) result.childrenMale24 = childrenMale24
                // ДІТИ: хлопці -26кг
                const childrenMale26 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 24 && parseFloat(at.weiht) <= 26)
                })
                if (childrenMale26.length > 0) result.childrenMale26 = childrenMale26
                // ДІТИ: хлопці -28кг
                const childrenMale28 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 26 && parseFloat(at.weiht) <= 28)
                })
                if (childrenMale28.length > 0) result.childrenMale28 = childrenMale28
                // ДІТИ: хлопці -30кг
                const childrenMale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 28 && parseFloat(at.weiht) <= 30)
                })
                if (childrenMale30.length > 0) result.childrenMale30 = childrenMale30
                // ДІТИ: хлопці -35кг
                const childrenMale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (childrenMale35.length > 0) result.childrenMale35 = childrenMale35

            // ДІТИ: хлопці -40кг
                const childrenMale40 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                })
                if (childrenMale40.length > 0) result.childrenMale40 = childrenMale40

                // ДІТИ: хлопці +40кг
                const childrenMale40Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 40)
                })
                if (childrenMale40Plus.length > 0) result.childrenMale40Plus = childrenMale40Plus

                // ДІТИ: дівчата -18кг
                const childrenFemale18 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) <= 18)
                })
                if (childrenFemale18.length > 0) result.childrenFemale18 = childrenFemale18
                // ДІТИ: дівчата -20кг
                const childrenFemale20 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 18 && parseFloat(at.weiht) <= 20)
                })
                if (childrenFemale20.length > 0) result.childrenFemale20 = childrenFemale20
                // ДІТИ: дівчата -22кг
                const childrenFemale22 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 20 && parseFloat(at.weiht) <= 22)
                })
                if (childrenFemale22.length > 0) result.childrenFemale22 = childrenFemale22
                // ДІТИ: дівчата -24кг
                const childrenFemale24 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 22 && parseFloat(at.weiht) <= 24)
                })
                if (childrenFemale24.length > 0) result.childrenFemale24 = childrenFemale24
                // ДІТИ: дівчата -26кг
                const childrenFemale26 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 24 && parseFloat(at.weiht) <= 26)
                })
                if (childrenFemale26.length > 0) result.childrenFemale26 = childrenFemale26
                // ДІТИ: дівчата -28кг
                const childrenFemale28 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 26 && parseFloat(at.weiht) <= 28)
                })
                if (childrenFemale28.length > 0) result.childrenFemale28 = childrenFemale28
                // ДІТИ: дівчата -30кг
                const childrenFemale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 28 && parseFloat(at.weiht) <= 30)
                })
                if (childrenFemale30.length > 0) result.childrenFemale30 = childrenFemale30               

                 // ДІТИ: дівчата -35кг
                 const childrenFemale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (childrenFemale35.length > 0) result.childrenFemale35 = childrenFemale35

                // ДІТИ: дівчата +35кг
                const childrenFemale35Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9 && parseFloat(at.weiht) > 35)
                })
                if (childrenFemale35Plus.length > 0) result.childrenFemale35Plus = childrenFemale35Plus
           //////////////////////////////////////////////////////////////////////////////////////

                //МОЛОДШІ ЮНАКИ: хлопці -24кг
                const jrYounkersMale24 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) <= 24)
                })
                if (jrYounkersMale24.length > 0) result.jrYounkersMale24 = jrYounkersMale24
                //МОЛОДШІ ЮНАКИ: хлопці -26кг
                const jrYounkersMale26 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 24 && parseFloat(at.weiht) <= 26)
                })
                if (jrYounkersMale26.length > 0) result.jrYounkersMale26 = jrYounkersMale26
                //МОЛОДШІ ЮНАКИ: хлопці -28кг
                const jrYounkersMale28 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 26 && parseFloat(at.weiht) <= 28)
                })
                if (jrYounkersMale28.length > 0) result.jrYounkersMale28 = jrYounkersMale28
                //МОЛОДШІ ЮНАКИ: хлопці -30кг
                const jrYounkersMale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 28 && parseFloat(at.weiht) <= 30)
                })
                if (jrYounkersMale30.length > 0) result.jrYounkersMale30 = jrYounkersMale30
                //МОЛОДШІ ЮНАКИ: хлопці -35кг
                const jrYounkersMale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (jrYounkersMale35.length > 0) result.jrYounkersMale35 = jrYounkersMale35
                 //МОЛОДШІ ЮНАКИ: хлопці -40кг
                const jrYounkersMale40 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                })
                if (jrYounkersMale40.length > 0) result.jrYounkersMale40 = jrYounkersMale40
                 //МОЛОДШІ ЮНАКИ: хлопці -45кг
                const jrYounkersMale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                })
                if (jrYounkersMale45.length > 0) result.jrYounkersMale45 = jrYounkersMale45
                //МОЛОДШІ ЮНАКИ: хлопці -50кг
                const jrYounkersMale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (jrYounkersMale50.length > 0) result.jrYounkersMale50 = jrYounkersMale50
               //МОЛОДШІ ЮНАКИ: хлопці +50кг
                const jrYounkersMale50Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 50)
                })
                if (jrYounkersMale50Plus.length > 0) result.jrYounkersMale50Plus = jrYounkersMale50Plus

                //МОЛОДШІ ЮНАКИ: дівчата -24кг
                const jrYounkersFemale24 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) <= 24)
                })
                if (jrYounkersFemale24.length > 0) result.jrYounkersFemale24 = jrYounkersFemale24
                //МОЛОДШІ ЮНАКИ: дівчата -26кг
                const jrYounkersFemale26 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 24 && parseFloat(at.weiht) <= 26)
                })
                if (jrYounkersFemale26.length > 0) result.jrYounkersFemale26 = jrYounkersFemale26
                //МОЛОДШІ ЮНАКИ: дівчата -28кг
                const jrYounkersFemale28 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 26 && parseFloat(at.weiht) <= 28)
                })
                if (jrYounkersFemale28.length > 0) result.jrYounkersFemale28 = jrYounkersFemale28
                //МОЛОДШІ ЮНАКИ: дівчата -30кг
                const jrYounkersFemale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 28 && parseFloat(at.weiht) <= 30)
                })
                if (jrYounkersFemale30.length > 0) result.jrYounkersFemale30 = jrYounkersFemale30
                //МОЛОДШІ ЮНАКИ: дівчата -35кг
                const jrYounkersFemale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (jrYounkersFemale35.length > 0) result.jrYounkersFemale35 = jrYounkersFemale35
                //МОЛОДШІ ЮНАКИ: дівчата -40кг
                const jrYounkersFemale40 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                })
                if (jrYounkersFemale40.length > 0) result.jrYounkersFemale40 = jrYounkersFemale40
                //МОЛОДШІ ЮНАКИ: дівчата -45кг
                const jrYounkersFemale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                })
                if (jrYounkersFemale45.length > 0) result.jrYounkersFemale45 = jrYounkersFemale45
                //МОЛОДШІ ЮНАКИ: дівчата -50кг
                const jrYounkersFemale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (jrYounkersFemale50.length > 0) result.jrYounkersFemale50 = jrYounkersFemale50
                //МОЛОДШІ ЮНАКИ: дівчата +50кг
                const jrYounkersFemale50Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11 && parseFloat(at.weiht) > 50)
                })
                if (jrYounkersFemale50Plus.length > 0) result.jrYounkersFemale50Plus = jrYounkersFemale50Plus

                ///////////////////////////////////////////////////////////////////////////////////////////////////
                
                //ЮНАКИ: хлопці -30кг
                const younkersMale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13  && parseFloat(at.weiht) <= 30)
                })
                if (younkersMale30.length > 0) result.younkersMale30 = younkersMale30
                //ЮНАКИ: хлопці -35кг
                const younkersMale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (younkersMale35.length > 0) result.younkersMale35 = younkersMale35
                //ЮНАКИ: хлопці -40кг
                const younkersMale40 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                })
                if (younkersMale40.length > 0) result.younkersMale40 = younkersMale40
                //ЮНАКИ: хлопці -45кг
                const younkersMale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                })
                if (younkersMale45.length > 0) result.younkersMale45 = younkersMale45
                //ЮНАКИ: хлопці -50кг
                const younkersMale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (younkersMale50.length > 0) result.younkersMale50 = younkersMale50
                //ЮНАКИ: хлопці -55кг
                const younkersMale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                })
                if (younkersMale55.length > 0) result.younkersMale55 = younkersMale55
                //ЮНАКИ: хлопці -60кг
                const younkersMale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (younkersMale60.length > 0) result.younkersMale60 = younkersMale60
                //ЮНАКИ: хлопці -65кг
                const younkersMale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (younkersMale65.length > 0) result.younkersMale65 = younkersMale65   
                //ЮНАКИ: хлопці +65кг
                const younkersMale65Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 65)
                })
                if (younkersMale65Plus.length > 0) result.younkersMale65Plus = younkersMale65Plus
                
                //ЮНАКИ: дівчата -30кг
                const younkersFemale30 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13  && parseFloat(at.weiht) <= 30)
                })
                if (younkersFemale30.length > 0) result.younkersFemale30 = younkersFemale30
                //ЮНАКИ: дівчата -35кг
                const younkersFemale35 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 30 && parseFloat(at.weiht) <= 35)
                })
                if (younkersFemale35.length > 0) result.younkersFemale35 = younkersFemale35
                //ЮНАКИ: дівчата -40кг
                const younkersFemale40 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                })
                if (younkersFemale40.length > 0) result.younkersFemale40 = younkersFemale40
                //ЮНАКИ: дівчата -45кг
                const younkersFemale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                })
                if (younkersFemale45.length > 0) result.younkersFemale45 = younkersFemale45
                //ЮНАКИ: дівчата -50кг
                const younkersFemale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (younkersFemale50.length > 0) result.younkersFemale50 = younkersFemale50
                //ЮНАКИ: дівчата -55кг
                const younkersFemale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                })
                if (younkersFemale55.length > 0) result.younkersFemale55 = younkersFemale55
                //ЮНАКИ: дівчата -60кг
                const younkersFemale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (younkersFemale60.length > 0) result.younkersFemale60 = younkersFemale60
                //ЮНАКИ: дівчата -65кг
                const younkersFemale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (younkersFemale65.length > 0) result.younkersFemale65 = younkersFemale65   
                //ЮНАКИ: дівчата +65кг
                const younkersFemale65Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13 && parseFloat(at.weiht) > 65)
                })
                if (younkersFemale65Plus.length > 0) result.younkersFemale65Plus = younkersFemale65Plus

            //////////////////////////////////////////////////////////////////////////

                    //КАДЕТИ: хлопці -35кг
                    const cadetsMale35 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 &&  parseFloat(at.weiht) <= 35)
                    })
                    if (cadetsMale35.length > 0) result.cadetsMale35 = cadetsMale35
                    //КАДЕТИ: хлопці -40кг
                    const cadetsMale40 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                    })
                    if (cadetsMale40.length > 0) result.cadetsMale40 = cadetsMale40
                    //КАДЕТИ: хлопці -45кг
                    const cadetsMale45 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                    })
                    if (cadetsMale45.length > 0) result.cadetsMale45 = cadetsMale45
                    //КАДЕТИ: хлопці -50кг
                    const cadetsMale50 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                    })
                    if (cadetsMale50.length > 0) result.cadetsMale50 = cadetsMale50
                    //КАДЕТИ: хлопці -55кг
                    const cadetsMale55 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                    })
                    if (cadetsMale55.length > 0) result.cadetsMale55 = cadetsMale55
                    //КАДЕТИ: хлопці -60кг
                    const cadetsMale60 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                    })
                    if (cadetsMale60.length > 0) result.cadetsMale60 = cadetsMale60
                    //КАДЕТИ: хлопці -65кг
                    const cadetsMale65 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                    })
                    if (cadetsMale65.length > 0) result.cadetsMale65 = cadetsMale65
                    //КАДЕТИ: хлопці -70кг
                    const cadetsMale70 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                    })
                    if (cadetsMale70.length > 0) result.cadetsMale70 = cadetsMale70
                    //КАДЕТИ: хлопці -75кг
                    const cadetsMale75 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                    })
                    if (cadetsMale75.length > 0) result.cadetsMale75 = cadetsMale75                    
                    //КАДЕТИ: хлопці +75кг
                    const cadetsMale75Plus = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 75)
                    })
                    if (cadetsMale75Plus.length > 0) result.cadetsMale75Plus = cadetsMale75Plus
   
                    //КАДЕТИ: дівчата -35кг
                    const cadetsFemale35 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 &&  parseFloat(at.weiht) <= 35)
                    })
                    if (cadetsFemale35.length > 0) result.cadetsFemale35 = cadetsFemale35
                    //КАДЕТИ: дівчата -40кг
                    const cadetsFemale40 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 35 && parseFloat(at.weiht) <= 40)
                    })
                    if (cadetsFemale40.length > 0) result.cadetsFemale40 = cadetsFemale40
                    //КАДЕТИ: дівчата -45кг
                    const cadetsFemale45 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                    })
                    if (cadetsFemale45.length > 0) result.cadetsFemale45 = cadetsFemale45
                    //КАДЕТИ: дівчата -50кг
                    const cadetsFemale50 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                    })
                    if (cadetsFemale50.length > 0) result.cadetsFemale50 = cadetsFemale50
                    //КАДЕТИ: дівчата -55кг
                    const cadetsFemale55 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                    })
                    if (cadetsFemale55.length > 0) result.cadetsFemale55 = cadetsFemale55
                    //КАДЕТИ: дівчата -60кг
                    const cadetsFemale60 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                    })
                    if (cadetsFemale60.length > 0) result.cadetsFemale60 = cadetsFemale60
                    //КАДЕТИ: дівчата -65кг
                    const cadetsFemale65 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                    })
                    if (cadetsFemale65.length > 0) result.cadetsFemale65 = cadetsFemale65
                    //КАДЕТИ: дівчата -70кг
                    const cadetsFemale70 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                    })
                    if (cadetsFemale70.length > 0) result.cadetsFemale70 = cadetsFemale70
                    //КАДЕТИ: дівчата -75кг
                    const cadetsFemale75 = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                    })
                    if (cadetsFemale75.length > 0) result.cadetsFemale75 = cadetsFemale75                    
                    //КАДЕТИ: дівчата +75кг
                    const cadetsFemale75Plus = entryAthletes.filter((at) => {
                        return (
                            at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15 && parseFloat(at.weiht) > 75)
                    })
                    if (cadetsFemale75Plus.length > 0) result.cadetsFemale75Plus = cadetsFemale75Plus
               
                 ////////////////////////////////////////////////////////////////////////////////////////////////////
                
                 //ЮНІОРИ: хлопці -45кг
                const juniorsMale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) <= 45)
                    })
                    if (juniorsMale45.length > 0) result.juniorsMale45 = juniorsMale45
                //ЮНІОРИ: хлопці -50кг
                const juniorsMale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (juniorsMale50.length > 0) result.juniorsMale50 = juniorsMale50
                //ЮНІОРИ: хлопці -55кг
                const juniorsMale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                })
                if (juniorsMale55.length > 0) result.juniorsMale55 = juniorsMale55
                //ЮНІОРИ: хлопці -60кг
                const juniorsMale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (juniorsMale60.length > 0) result.juniorsMale60 = juniorsMale60
                //ЮНІОРИ: хлопці -65кг
                const juniorsMale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (juniorsMale65.length > 0) result.juniorsMale65 = juniorsMale65
                //ЮНІОРИ: хлопці -70кг
                const juniorsMale70 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                })
                if (juniorsMale70.length > 0) result.juniorsMale70 = juniorsMale70
                //ЮНІОРИ: хлопці -75кг
                const juniorsMale75 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                })
                if (juniorsMale75.length > 0) result.juniorsMale75 = juniorsMale75
                //ЮНІОРИ: хлопці -80кг
                const juniorsMale80 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 75 && parseFloat(at.weiht) <= 80)
                })
                if (juniorsMale80.length > 0) result.juniorsMale80 = juniorsMale80
                //ЮНІОРИ: хлопці -85кг
                const juniorsMale85 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 80 && parseFloat(at.weiht) <= 85)
                })
                if (juniorsMale85.length > 0) result.juniorsMale85 = juniorsMale85
                //ЮНІОРИ: хлопці +85кг
                const juniorsMale85Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 85)
                })
                if (juniorsMale85Plus.length > 0) result.juniorsMale85Plus = juniorsMale85Plus

                //ЮНІОРИ: дівчата -40кг
                const juniorsFemale40 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) <= 40)
                })
                if (juniorsFemale40.length > 0) result.juniorsFemale40 = juniorsFemale40
                //ЮНІОРИ: дівчата -45кг
                const juniorsFemale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 40 && parseFloat(at.weiht) <= 45)
                })
                if (juniorsFemale45.length > 0) result.juniorsFemale45 = juniorsFemale45
                //ЮНІОРИ: дівчата -50кг
                const juniorsFemale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (juniorsFemale50.length > 0) result.juniorsFemale50 = juniorsFemale50
                //ЮНІОРИ: дівчата -55кг
                const juniorsFemale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                })
                if (juniorsFemale55.length > 0) result.juniorsFemale55 = juniorsFemale55
                //ЮНІОРИ: дівчата -60кг
                const juniorsFemale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (juniorsFemale60.length > 0) result.juniorsFemale60 = juniorsFemale60
                //ЮНІОРИ: дівчата -65кг
                const juniorsFemale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (juniorsFemale65.length > 0) result.juniorsFemale65 = juniorsFemale65
                //ЮНІОРИ: дівчата -70кг
                const juniorsFemale70 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                })
                if (juniorsFemale70.length > 0) result.juniorsFemale70 = juniorsFemale70
                //ЮНІОРИ: дівчата -75кг
                const juniorsFemale75 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                })
                if (juniorsFemale75.length > 0) result.juniorsFemale75 = juniorsFemale75
                //ЮНІОРИ: дівчата -80кг
                const juniorsFemale80 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 75 && parseFloat(at.weiht) <= 80)
                })
                if (juniorsFemale80.length > 0) result.juniorsFemale80 = juniorsFemale80
                //ЮНІОРИ: дівчата +80кг
                const juniorsFemale80Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17 && parseFloat(at.weiht) > 80)
                })
                if (juniorsFemale80Plus.length > 0) result.juniorsFemale80Plus = juniorsFemale80Plus
                
                ///////////////////////////////////////////////////////////////////////////

                //ДОРОСЛІ: чоловіки -55кг
                const adultsMale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) <= 55)
                })
                if (adultsMale55.length > 0) result.adultsMale55 = adultsMale55
                //ДОРОСЛІ: чоловіки -60кг
                const adultsMale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (adultsMale60.length > 0) result.adultsMale60 = adultsMale60
                //ДОРОСЛІ: чоловіки -65кг
                const adultsMale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (adultsMale65.length > 0) result.adultsMale65 = adultsMale65
                //ДОРОСЛІ: чоловіки -70кг
                const adultsMale70 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                })
                if (adultsMale70.length > 0) result.adultsMale70 = adultsMale70
                //ДОРОСЛІ: чоловіки -75кг
                const adultsMale75 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                })
                if (adultsMale75.length > 0) result.adultsMale75 = adultsMale75
                //ДОРОСЛІ: чоловіки -80кг
                const adultsMale80 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 75 && parseFloat(at.weiht) <= 80)
                })
                if (adultsMale80.length > 0) result.adultsMale80 = adultsMale80
                //ДОРОСЛІ: чоловіки -85кг
                const adultsMale85 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 80 && parseFloat(at.weiht) <= 85)
                })
                if (adultsMale85.length > 0) result.adultsMale85 = adultsMale85
                //ДОРОСЛІ: чоловіки -90кг
                const adultsMale90 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 85 && parseFloat(at.weiht) <= 90)
                })
                if (adultsMale90.length > 0) result.adultsMale90 = adultsMale90
                //ДОРОСЛІ: чоловіки -100кг
                const adultsMale100 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 90 && parseFloat(at.weiht) <= 100)
                })
                if (adultsMale100.length > 0) result.adultsMale100 = adultsMale100
                //ДОРОСЛІ: чоловіки +100кг
                const adultsMale100Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 100)
                })
                if (adultsMale100Plus.length > 0) result.adultsMale100Plus = adultsMale100Plus

                //ДОРОСЛІ: жінки -45кг
                const adultsFemale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) <= 45)
                })
                if (adultsFemale45.length > 0) result.adultsFemale45 = adultsFemale45
                //ДОРОСЛІ: жінки -50кг
                const adultsFemale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (adultsFemale50.length > 0) result.adultsFemale50 = adultsFemale50
                //ДОРОСЛІ: жінки -55кг
                const adultsFemale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                })
                if (adultsFemale55.length > 0) result.adultsFemale55 = adultsFemale55
                //ДОРОСЛІ: жінки -60кг
                const adultsFemale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (adultsFemale60.length > 0) result.adultsFemale60 = adultsFemale60
                //ДОРОСЛІ: жінки -65кг
                const adultsFemale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (adultsFemale65.length > 0) result.adultsFemale65 = adultsFemale65
                //ДОРОСЛІ: жінки -70кг
                const adultsFemale70 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                })
                if (adultsFemale70.length > 0) result.adultsFemale70 = adultsFemale70
                //ДОРОСЛІ: жінки -75кг
                const adultsFemale75 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                })
                if (adultsFemale75.length > 0) result.adultsFemale75 = adultsFemale75
                //ДОРОСЛІ: жінки -80кг
                const adultsFemale80 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 75 && parseFloat(at.weiht) <= 80)
                })
                if (adultsFemale80.length > 0) result.adultsFemale80 = adultsFemale80
                //ДОРОСЛІ: жінки +80кг
                const adultsFemale80Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39 && parseFloat(at.weiht) > 80)
                })
                if (adultsFemale80Plus.length > 0) result.adultsFemale80Plus = adultsFemale80Plus

                ///////////////////////////////////////////////////////////////////////////

                //ВЕТЕРАНИ: чоловіки -55кг
                const veteransMale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) <= 55)
                })
                if (veteransMale55.length > 0) result.veteransMale55 = veteransMale55
                //ВЕТЕРАНИ: чоловіки -60кг
                const veteransMale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (veteransMale60.length > 0) result.veteransMale60 = veteransMale60
                //ВЕТЕРАНИ: чоловіки -65кг
                const veteransMale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (veteransMale65.length > 0) result.veteransMale65 = veteransMale65
                //ВЕТЕРАНИ: чоловіки -70кг
                const veteransMale70 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                })
                if (veteransMale70.length > 0) result.veteransMale70 = veteransMale70
                //ВЕТЕРАНИ: чоловіки -75кг
                const veteransMale75 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                })
                if (veteransMale75.length > 0) result.veteransMale75 = veteransMale75
                //ВЕТЕРАНИ: чоловіки -80кг
                const veteransMale80 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 75 && parseFloat(at.weiht) <= 80)
                })
                if (veteransMale80.length > 0) result.veteransMale80 = veteransMale80
                //ВЕТЕРАНИ: чоловіки -85кг
                const veteransMale85 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 80 && parseFloat(at.weiht) <= 85)
                })
                if (veteransMale85.length > 0) result.veteransMale85 = veteransMale85
                //ВЕТЕРАНИ: чоловіки -90кг
                const veteransMale90 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40 && parseFloat(at.weiht) > 85 && parseFloat(at.weiht) <= 90)
                })
                if (veteransMale90.length > 0) result.veteransMale90 = veteransMale90
                //ВЕТЕРАНИ: чоловіки -100кг
                const veteransMale100 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 90 && parseFloat(at.weiht) <= 100)
                })
                if (veteransMale100.length > 0) result.veteransMale100 = veteransMale100
                //ВЕТЕРАНИ: чоловіки +100кг
                const veteransMale100Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'male' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 100)
                })
                if (veteransMale100Plus.length > 0) result.veteransMale100Plus = veteransMale100Plus

                //ВЕТЕРАНИ: жінки -45кг
                const veteransFemale45 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) <= 45)
                })
                if (veteransFemale45.length > 0) result.veteransFemale45 = veteransFemale45
                //ВЕТЕРАНИ: жінки -50кг
                const veteransFemale50 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 45 && parseFloat(at.weiht) <= 50)
                })
                if (veteransFemale50.length > 0) result.veteransFemale50 = veteransFemale50
                //ВЕТЕРАНИ: жінки -55кг
                const veteransFemale55 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 50 && parseFloat(at.weiht) <= 55)
                })
                if (veteransFemale55.length > 0) result.veteransFemale55 = veteransFemale55
                //ВЕТЕРАНИ: жінки -60кг
                const veteransFemale60 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 55 && parseFloat(at.weiht) <= 60)
                })
                if (veteransFemale60.length > 0) result.veteransFemale60 = veteransFemale60
                //ВЕТЕРАНИ: жінки -65кг
                const veteransFemale65 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 60 && parseFloat(at.weiht) <= 65)
                })
                if (veteransFemale65.length > 0) result.veteransFemale65 = veteransFemale65
                //ВЕТЕРАНИ: жінки -70кг
                const veteransFemale70 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 65 && parseFloat(at.weiht) <= 70)
                })
                if (veteransFemale70.length > 0) result.veteransFemale70 = veteransFemale70
                //ВЕТЕРАНИ: жінки -75кг
                const veteransFemale75 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 70 && parseFloat(at.weiht) <= 75)
                })
                if (veteransFemale75.length > 0) result.veteransFemale75 = veteransFemale75
                //ВЕТЕРАНИ: жінки -80кг
                const veteransFemale80 = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40 && parseFloat(at.weiht) > 75 && parseFloat(at.weiht) <= 80)
                })
                if (veteransFemale80.length > 0) result.veteransFemale80 = veteransFemale80
                //ВЕТЕРАНИ: жінки +80кг
                const veteransFemale80Plus = entryAthletes.filter((at) => {
                    return (
                        at.sex === 'female' && state.age(at.birthday) >= 40  && parseFloat(at.weiht) > 80)
                })
                if (veteransFemale80Plus.length > 0) result.veteransFemale80Plus = veteransFemale80Plus

                return result


            } else { console.error('В параметр athletes в state.tournConstructor.sortAthletesByCategories не було надано даних') }
        },

        sortAthletesByTeam: (athletes) => { //сортуємо спортсменів по командах для подання заявки головою осередка

            const athletesByTeam = {};

            athletes.forEach(athlete => {
                const teamName = Array.isArray(athlete)?athlete[0].team:athlete.team;

                if (!athletesByTeam[teamName]) {
                    athletesByTeam[teamName] = [];
                }

                athletesByTeam[teamName].push(athlete);
            });

            // Сортуємо спортсменів в кожній команді за потрібним критерієм, наприклад, за ім'ям
            // Object.keys(athletesByTeam).forEach(teamName => {
            //     athletesByTeam[teamName].sort((a, b) => a.personName.localeCompare(b.personName));
            // });

            return athletesByTeam;
        },
        battlesData: (numberOfAthletes, athleteList, gridsDB) => { //формування графіку поєдинків для відправлення тренеру по  пошті

            let battles
            if (numberOfAthletes == 1) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>;<br/>`
                ]
                return  battles
            }
            if (numberOfAthletes == 2) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 3) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; <br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 4) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 5) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>, ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 6) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 7) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 8) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>;<br/>`,

                ]
                return  battles
            }
            if (numberOfAthletes == 9) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 10) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>;<br/>`,

                ]
                return battles
            }
            if (numberOfAthletes == 11) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>;<br/>`,




                ]
                return battles
            }
            if (numberOfAthletes == 12) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,

                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>;<br/>`,

                ]
                return battles
            }
            if (numberOfAthletes == 13) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>;<br/>`,

                ]
                return battles
            }
            if (numberOfAthletes == 14) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 15) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>;<br/>`,

                ]
                return battles
            }
            if (numberOfAthletes == 16) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>;<br/>`,

                ]
                return battles
            }
            if (numberOfAthletes == 17) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 18) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 19) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 20) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 21) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 22) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 23) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 24) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 25) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp;  ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp;  ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 26) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 27) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp;  ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp;  ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>;<br/>`,
                ]
                return battles
            }
            if (numberOfAthletes == 28) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 29) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>;<br/>`

                ]
                return battles
            }
            if (numberOfAthletes == 30) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>;<br/>`,

                ]
                return battles
            }
            if (numberOfAthletes == 31) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>;<br/>`

                ]
                return battles
            }
            if (numberOfAthletes == 32) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`,
                    `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>;<br/>`
                ]
                return battles
            }
            if (numberOfAthletes == 33) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                    `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>;<br/>`,
                ]
                return  battles          
            }            
            if (numberOfAthletes == 34) {
                    battles = [
                        `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                        `${athleteList[33].teamPoomsae?state.poomsaeTeamString(athleteList[33].teamPoomsae):athleteList[33]['secondName']+' '+athleteList[33]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>;<br/>`,
                      
                    ] 
                    return battles
                }
            if (numberOfAthletes == 35) {
                    battles = [
                        `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[33].teamPoomsae?state.poomsaeTeamString(athleteList[33].teamPoomsae):athleteList[33]['secondName']+' '+athleteList[33]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                        `${athleteList[34].teamPoomsae?state.poomsaeTeamString(athleteList[34].teamPoomsae):athleteList[34]['secondName']+' '+athleteList[34]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>;<br/>`,
                       
                    ] 
                    return battles
                }  
            if (numberOfAthletes == 36) {
                battles = [
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[33].teamPoomsae?state.poomsaeTeamString(athleteList[33].teamPoomsae):athleteList[33]['secondName']+' '+athleteList[33]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[34].teamPoomsae?state.poomsaeTeamString(athleteList[34].teamPoomsae):athleteList[34]['secondName']+' '+athleteList[34]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    `${athleteList[35].teamPoomsae?state.poomsaeTeamString(athleteList[35].teamPoomsae):athleteList[35]['secondName']+' '+athleteList[35]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>;<br/>`,
                    ] 
                    return battles
                } 
            if (numberOfAthletes == 37) {
                battles = [ 
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[33].teamPoomsae?state.poomsaeTeamString(athleteList[33].teamPoomsae):athleteList[33]['secondName']+' '+athleteList[33]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[34].teamPoomsae?state.poomsaeTeamString(athleteList[34].teamPoomsae):athleteList[34]['secondName']+' '+athleteList[34]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[35].teamPoomsae?state.poomsaeTeamString(athleteList[35].teamPoomsae):athleteList[35]['secondName']+' '+athleteList[35]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    `${athleteList[36].teamPoomsae?state.poomsaeTeamString(athleteList[36].teamPoomsae):athleteList[36]['secondName']+' '+athleteList[36]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>;<br/>`,
                    
                    ] 
                    return  battles 
                }
            if (numberOfAthletes == 38) {
                battles = [ 
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[33].teamPoomsae?state.poomsaeTeamString(athleteList[33].teamPoomsae):athleteList[33]['secondName']+' '+athleteList[33]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[34].teamPoomsae?state.poomsaeTeamString(athleteList[34].teamPoomsae):athleteList[34]['secondName']+' '+athleteList[34]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[35].teamPoomsae?state.poomsaeTeamString(athleteList[35].teamPoomsae):athleteList[35]['secondName']+' '+athleteList[35]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[36].teamPoomsae?state.poomsaeTeamString(athleteList[36].teamPoomsae):athleteList[36]['secondName']+' '+athleteList[36]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    `${athleteList[37].teamPoomsae?state.poomsaeTeamString(athleteList[37].teamPoomsae):athleteList[37]['secondName']+' '+athleteList[37]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>;<br/>`,
                    
                ]
                return battles
                }
            if (numberOfAthletes == 39) {
                battles = [ 
                    `${athleteList[0].teamPoomsae?state.poomsaeTeamString(athleteList[0].teamPoomsae):athleteList[0]['secondName']+' '+athleteList[0]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[1].teamPoomsae?state.poomsaeTeamString(athleteList[1].teamPoomsae):athleteList[1]['secondName']+' '+athleteList[1]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[0]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[2].teamPoomsae?state.poomsaeTeamString(athleteList[2].teamPoomsae):athleteList[2]['secondName']+' '+athleteList[2]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[3].teamPoomsae?state.poomsaeTeamString(athleteList[3].teamPoomsae):athleteList[3]['secondName']+' '+athleteList[3]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[1]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[19]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[4].teamPoomsae?state.poomsaeTeamString(athleteList[4].teamPoomsae):athleteList[4]['secondName']+' '+athleteList[4]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[5].teamPoomsae?state.poomsaeTeamString(athleteList[5].teamPoomsae):athleteList[5]['secondName']+' '+athleteList[5]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[2]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[23]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[6].teamPoomsae?state.poomsaeTeamString(athleteList[6].teamPoomsae):athleteList[6]['secondName']+' '+athleteList[6]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[7].teamPoomsae?state.poomsaeTeamString(athleteList[7].teamPoomsae):athleteList[7]['secondName']+' '+athleteList[7]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[3]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[8].teamPoomsae?state.poomsaeTeamString(athleteList[8].teamPoomsae):athleteList[8]['secondName']+' '+athleteList[8]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[9].teamPoomsae?state.poomsaeTeamString(athleteList[9].teamPoomsae):athleteList[9]['secondName']+' '+athleteList[9]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[4]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[24]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[31]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[10].teamPoomsae?state.poomsaeTeamString(athleteList[10].teamPoomsae):athleteList[10]['secondName']+' '+athleteList[10]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[11].teamPoomsae?state.poomsaeTeamString(athleteList[11].teamPoomsae):athleteList[11]['secondName']+' '+athleteList[11]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[5]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[12].teamPoomsae?state.poomsaeTeamString(athleteList[12].teamPoomsae):athleteList[12]['secondName']+' '+athleteList[12]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[13].teamPoomsae?state.poomsaeTeamString(athleteList[13].teamPoomsae):athleteList[13]['secondName']+' '+athleteList[13]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[6]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[25]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[14].teamPoomsae?state.poomsaeTeamString(athleteList[14].teamPoomsae):athleteList[14]['secondName']+' '+athleteList[14]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[15].teamPoomsae?state.poomsaeTeamString(athleteList[15].teamPoomsae):athleteList[15]['secondName']+' '+athleteList[15]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[7]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[16].teamPoomsae?state.poomsaeTeamString(athleteList[16].teamPoomsae):athleteList[16]['secondName']+' '+athleteList[16]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[17].teamPoomsae?state.poomsaeTeamString(athleteList[17].teamPoomsae):athleteList[17]['secondName']+' '+athleteList[17]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[8]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[18].teamPoomsae?state.poomsaeTeamString(athleteList[18].teamPoomsae):athleteList[18]['secondName']+' '+athleteList[18]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[19].teamPoomsae?state.poomsaeTeamString(athleteList[19].teamPoomsae):athleteList[19]['secondName']+' '+athleteList[19]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[9]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[20]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[26]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[32]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[35]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[20].teamPoomsae?state.poomsaeTeamString(athleteList[20].teamPoomsae):athleteList[20]['secondName']+' '+athleteList[20]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[21].teamPoomsae?state.poomsaeTeamString(athleteList[21].teamPoomsae):athleteList[21]['secondName']+' '+athleteList[21]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[10]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[22].teamPoomsae?state.poomsaeTeamString(athleteList[22].teamPoomsae):athleteList[22]['secondName']+' '+athleteList[22]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[23].teamPoomsae?state.poomsaeTeamString(athleteList[23].teamPoomsae):athleteList[23]['secondName']+' '+athleteList[23]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[11]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[21]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[24].teamPoomsae?state.poomsaeTeamString(athleteList[24].teamPoomsae):athleteList[24]['secondName']+' '+athleteList[24]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[25].teamPoomsae?state.poomsaeTeamString(athleteList[25].teamPoomsae):athleteList[25]['secondName']+' '+athleteList[25]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[12]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[27]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[26].teamPoomsae?state.poomsaeTeamString(athleteList[26].teamPoomsae):athleteList[26]['secondName']+' '+athleteList[26]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[27].teamPoomsae?state.poomsaeTeamString(athleteList[27].teamPoomsae):athleteList[27]['secondName']+' '+athleteList[27]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[13]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[28].teamPoomsae?state.poomsaeTeamString(athleteList[28].teamPoomsae):athleteList[28]['secondName']+' '+athleteList[28]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[29].teamPoomsae?state.poomsaeTeamString(athleteList[29].teamPoomsae):athleteList[29]['secondName']+' '+athleteList[29]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[14]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[28]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[33]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[30].teamPoomsae?state.poomsaeTeamString(athleteList[30].teamPoomsae):athleteList[30]['secondName']+' '+athleteList[30]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[31].teamPoomsae?state.poomsaeTeamString(athleteList[31].teamPoomsae):athleteList[31]['secondName']+' '+athleteList[31]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[15]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[32].teamPoomsae?state.poomsaeTeamString(athleteList[32].teamPoomsae):athleteList[32]['secondName']+' '+athleteList[32]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[33].teamPoomsae?state.poomsaeTeamString(athleteList[33].teamPoomsae):athleteList[33]['secondName']+' '+athleteList[33]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[16]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[29]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[34].teamPoomsae?state.poomsaeTeamString(athleteList[34].teamPoomsae):athleteList[34]['secondName']+' '+athleteList[34]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[35].teamPoomsae?state.poomsaeTeamString(athleteList[35].teamPoomsae):athleteList[35]['secondName']+' '+athleteList[35]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[17]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[36].teamPoomsae?state.poomsaeTeamString(athleteList[36].teamPoomsae):athleteList[36]['secondName']+' '+athleteList[36]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[37].teamPoomsae?state.poomsaeTeamString(athleteList[37].teamPoomsae):athleteList[37]['secondName']+' '+athleteList[37]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[18]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    `${athleteList[38].teamPoomsae?state.poomsaeTeamString(athleteList[38].teamPoomsae):athleteList[38]['secondName']+' '+athleteList[38]['personName']}&nbsp;&nbsp; - &nbsp;&nbsp;${gridsDB.cort}.<b>${gridsDB.battleNumbers[22]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[30]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[34]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[36]}</b>,&nbsp; ${gridsDB.cort}.<b>${gridsDB.battleNumbers[37]}</b>;<br/>`,
                    
                ]
                return battles
                }          
        },
        checkForDuplicates: (arr, category,poomse) => {//перевірка пар на співпадіння представників однієї комаднди в першому двобої
           
            let result = false;
            
            for (let i = 0; i < arr.length; i++) {
                const teamSet = new Set();

                for (const key in arr[i]) {
                    if (arr[i].hasOwnProperty(key)) {
                        const currentObject = arr[i][key];

                        // Перевіряємо, чи об'єкт не є null і має властивість 'team'
                        if (currentObject && 'team' in currentObject) {
                            const currentTeam = currentObject.team;

                            if (teamSet.has(currentTeam)) {
                                result = true;
                                break;
                            } else {
                                teamSet.add(currentTeam);
                            }
                        }
                    }
                }

                if (result) {
                    break;
                }
            }
        // }

            if (result) {
                state.gridsReport.push(`<p>В категорії 
                <a href="#${category}">
                <span>${poomse?lang.poomsaeCatUkr("ukr",category):state.weightCatUkr(category)} </span>
                </a>
                в першому колі двобоїв сходяться представники однієї команди!</p>`);
            }
        },

        poomseCategories:{//категорії пхумсе
            traditional:{
                personal:(entryAthletes,classSwitcher)=>{                   
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []

                        //МОЛОДШІ ЮНАКИ: чоловіки особисті
                const jrYounkersMaleTraditionalPersonale  = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 4 && state.age(at.birthday) <= 9)
                })
                if (jrYounkersMaleTraditionalPersonale.length > 0) result.push({category:'jrYounkersMaleTraditionalPersonale'+classSwitcher,athletes:jrYounkersMaleTraditionalPersonale} );
                  
                //МОЛОДШІ ЮНАКИ: жінки особисті
                const jrYounkersFemaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 4 && state.age(at.birthday) <= 9)
                })
                if (jrYounkersFemaleTraditionalPersonale.length > 0) result.push({category:'jrYounkersFemaleTraditionalPersonale'+classSwitcher,athletes:jrYounkersFemaleTraditionalPersonale} )
                        //ЮНАКИ: чоловіки особисті
                const younkersMaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
                })
                if (younkersMaleTraditionalPersonale.length > 0) result.push({category:'younkersMaleTraditionalPersonale'+classSwitcher,athletes:younkersMaleTraditionalPersonale} )
                        //ЮНАКИ: жінки особисті
                const younkersFemaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
                })
                if (younkersFemaleTraditionalPersonale.length > 0) result.push({category:'younkersFemaleTraditionalPersonale'+classSwitcher,athletes:younkersFemaleTraditionalPersonale} )
                        //КАДЕТИ: чоловіки особисті
                const cadetsMaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 14)
                })
                if (cadetsMaleTraditionalPersonale.length > 0) result.push({category:'cadetsMaleTraditionalPersonale'+classSwitcher,athletes:cadetsMaleTraditionalPersonale} )
                        //КАДЕТИ: жінки особисті
                const cadetsFemaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 14)
                })
                if (cadetsFemaleTraditionalPersonale.length > 0) result.push({category:'cadetsFemaleTraditionalPersonale'+classSwitcher,athletes:cadetsFemaleTraditionalPersonale} )
                        //ЮНІОРИ: чоловіки особисті
                const juniorsMaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 15 && state.age(at.birthday) <= 17)
                })
                if (juniorsMaleTraditionalPersonale.length > 0) result.push({category:'juniorsMaleTraditionalPersonale'+classSwitcher,athletes:juniorsMaleTraditionalPersonale} )
                        //ЮНІОРИ: жінки особисті
                const juniorsFemaleTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 15 && state.age(at.birthday) <= 17)
                })
                if (juniorsFemaleTraditionalPersonale.length > 0) result.push({category:'juniorsFemaleTraditionalPersonale'+classSwitcher,athletes:juniorsFemaleTraditionalPersonale} )
                        //ДОРОСЛІ ДО 30: чоловіки особисті
                const adultsMale30TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 30)
                })
                if (adultsMale30TraditionalPersonale.length > 0) result.push({category:'adultsMale30TraditionalPersonale'+classSwitcher,athletes:adultsMale30TraditionalPersonale} )
                        //ДОРОСЛІ ДО 30: жінки особисті
                const adultsFemale30TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 30)
                })
                if (adultsFemale30TraditionalPersonale.length > 0) result.push({category:'adultsFemale30TraditionalPersonale'+classSwitcher,athletes:adultsFemale30TraditionalPersonale} )
                        //ДОРОСЛІ ДО 40: чоловіки особисті
                const adultsMale40TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 31 && state.age(at.birthday) <= 40)
                })
                if (adultsMale40TraditionalPersonale.length > 0) result.push({category:'adultsMale40TraditionalPersonale'+classSwitcher,athletes:adultsMale40TraditionalPersonale} )
                         //ДОРОСЛІ ДО 40: жінки особисті
                const adultsFemale40TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 31 && state.age(at.birthday) <= 40)
                })
                if (adultsFemale40TraditionalPersonale.length > 0) result.push({category:'adultsFemale40TraditionalPersonale'+classSwitcher,athletes:adultsFemale40TraditionalPersonale} )
                        //ДОРОСЛІ ДО 50: чоловіки особисті
                const adultsMale50TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 41 && state.age(at.birthday) <= 50)
                })
                if (adultsMale50TraditionalPersonale.length > 0) result.push({category:'adultsMale50TraditionalPersonale'+classSwitcher,athletes:adultsMale50TraditionalPersonale} )
                         //ДОРОСЛІ ДО 50: жінки особисті
                const adultsFemale50TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 41 && state.age(at.birthday) <= 50)
                })
                if (adultsFemale50TraditionalPersonale.length > 0) result.push({category:'adultsFemale50TraditionalPersonale'+classSwitcher,athletes:adultsFemale50TraditionalPersonale} )
                        //ДОРОСЛІ ДО 60: чоловіки особисті
                const adultsMale60TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 51 && state.age(at.birthday) <= 60)
                })
                if (adultsMale60TraditionalPersonale.length > 0) result.push({category:'adultsMale60TraditionalPersonale'+classSwitcher,athletes:adultsMale60TraditionalPersonale} )
                         //ДОРОСЛІ ДО 60: жінки особисті
                const adultsFemale60TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 51 && state.age(at.birthday) <= 60)
                })
                if (adultsFemale60TraditionalPersonale.length > 0) result.push({category:'adultsFemale60TraditionalPersonale'+classSwitcher,athletes:adultsFemale60TraditionalPersonale} )
                        //ДОРОСЛІ ДО 65: чоловіки особисті
                const adultsMale65TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 61 && state.age(at.birthday) <= 65)
                })
                if (adultsMale65TraditionalPersonale.length > 0) result.push({category:'adultsMale65TraditionalPersonale'+classSwitcher,athletes:adultsMale65TraditionalPersonale} )
                          //ДОРОСЛІ ДО 65: жінки особисті
                const adultsFemale65TraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 61 && state.age(at.birthday) <= 65)
                })
                if (adultsFemale65TraditionalPersonale.length > 0) result.push({category:'adultsFemale65TraditionalPersonale'+classSwitcher,athletes:adultsFemale65TraditionalPersonale} )
                        //ДОРОСЛІ ПОНАД 65: чоловіки особисті
                const adultsMale65PlusTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 66 )
                })
                if (adultsMale65PlusTraditionalPersonale.length > 0) result.push({category:'adultsMale65PlusTraditionalPersonale'+classSwitcher,athletes:adultsMale65PlusTraditionalPersonale} )
                     //ДОРОСЛІ ПОНАД 65: жінки особисті
                const adultsFemale65PlusTraditionalPersonale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 66)
                })
                if (adultsFemale65PlusTraditionalPersonale.length > 0) result.push({category:'adultsFemale65PlusTraditionalPersonale'+classSwitcher,athletes:adultsFemale65PlusTraditionalPersonale} )
                    console.log('result: ',result)     
                return result    
                    }                    
                },
                couple:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result =[]

                        //МОЛОДШІ ЮНАКИ:  Пари
                const jrYounkersTraditionalCouple =[] 
                jrYounkersTraditionalCouple.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 4 && state.age(at.birthday) <= 9)
                }))
                if (jrYounkersTraditionalCouple[0].length > 0)result.push({category:'jrYounkersTraditionalCouple'+classSwitcher,athletes:jrYounkersTraditionalCouple} )
                         //ЮНАКИ:  Пари
                const younkersTraditionalCouple = []
                younkersTraditionalCouple.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
                }))
                if (younkersTraditionalCouple[0].length > 0) result.push({category:'younkersTraditionalCouple'+classSwitcher,athletes:younkersTraditionalCouple} )
                       //КАДЕТИ:  Пари
                const cadetsTraditionalCouple =[]
                cadetsTraditionalCouple.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 12 && state.age(at.birthday) <= 14)
                }))
                if (cadetsTraditionalCouple[0].length > 0) result.push({category:'cadetsTraditionalCouple'+classSwitcher,athletes:cadetsTraditionalCouple} )
                       //ЮНІОРИ:  Пари
                const juniorsTraditionalCouple =[]
                juniorsTraditionalCouple.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 15 && state.age(at.birthday) <= 17)
                }))
                if (juniorsTraditionalCouple[0].length > 0) result.push({category:'juniorsTraditionalCouple'+classSwitcher,athletes:juniorsTraditionalCouple} )
                       //ДОРОСЛІ ДО 30:  Пари
                const adults30TraditionalCouple =[]
                adults30TraditionalCouple.push( entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 18 && state.age(at.birthday) <= 30)
                }))
                if (adults30TraditionalCouple[0].length > 0) result.push({category:'adults30TraditionalCouple'+classSwitcher,athletes:adults30TraditionalCouple} )
                         //ДОРОСЛІ ДО 40:  Пари
                const adults40TraditionalCouple =[]
                adults40TraditionalCouple.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 31 && state.age(at.birthday) <= 40)
                }))
                if (adults40TraditionalCouple[0].length > 0) result.push({category:'adults40TraditionalCouple'+classSwitcher,athletes:adults40TraditionalCouple} )
                         //ДОРОСЛІ ДО 50:  Пари
                const adults50TraditionalCouple =[]
                adults50TraditionalCouple.push( entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 41 && state.age(at.birthday) <= 50)
                }))
                if (adults50TraditionalCouple[0].length > 0) result.push({category:'adults50TraditionalCouple'+classSwitcher,athletes:adults50TraditionalCouple} )
                         //ДОРОСЛІ ДО 60:  Пари
                const adults60TraditionalCouple =[]
                adults60TraditionalCouple.push( entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 51 && state.age(at.birthday) <= 60)
                }))
                if (adults60TraditionalCouple[0].length > 0) result.push({category:'adults60TraditionalCouple'+classSwitcher,athletes:adults60TraditionalCouple} )
                         //ДОРОСЛІ ПОНАД 60:  Пари
                const adults60PlusTraditionalCouple =[]
                 adults60PlusTraditionalCouple.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 61 )
                }))
                if (adults60PlusTraditionalCouple[0].length > 0) result.push({category:'adults60PlusTraditionalCouple'+classSwitcher,athletes:adults60PlusTraditionalCouple} )
                      
                return result    
                    }                    
                },
                team:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []
                
                        //МОЛОДШІ ЮНАКИ: чоловіки Команди
                const jrYounkersMaleTraditionalTeam = []
                jrYounkersMaleTraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 4 && state.age(at.birthday) <= 9)
                }))
                if (jrYounkersMaleTraditionalTeam[0].length > 0)  result.push({category:'jrYounkersMaleTraditionalTeam'+classSwitcher,athletes:jrYounkersMaleTraditionalTeam} )
                        //МОЛОДШІ ЮНАКИ: жінки Команди
                const jrYounkersFemaleTraditionalTeam = []
               jrYounkersFemaleTraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 4 && state.age(at.birthday) <= 9)
                }))
                if (jrYounkersFemaleTraditionalTeam[0].length > 0) result.push({category:'jrYounkersFemaleTraditionalTeam'+classSwitcher,athletes:jrYounkersFemaleTraditionalTeam} )
                        //ЮНАКИ: чоловіки Команди
                const younkersMaleTraditionalTeam =[]
                younkersMaleTraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
                }))
                if (younkersMaleTraditionalTeam[0].length > 0) result.push({category:'younkersMaleTraditionalTeam'+classSwitcher,athletes:younkersMaleTraditionalTeam} )
                        //ЮНАКИ: жінки Команди
                const younkersFemaleTraditionalTeam =[]
                 younkersFemaleTraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
                }))
                if (younkersFemaleTraditionalTeam[0].length > 0) result.push({category:'younkersFemaleTraditionalTeam'+classSwitcher,athletes:younkersFemaleTraditionalTeam} )
                        //КАДЕТИ: чоловіки Команди
                const cadetsMaleTraditionalTeam =[]
                cadetsMaleTraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 14)
                }))
                if (cadetsMaleTraditionalTeam[0].length > 0) result.push({category:'cadetsMaleTraditionalTeam'+classSwitcher,athletes:cadetsMaleTraditionalTeam} )
                        //КАДЕТИ: жінки Команди
                const cadetsFemaleTraditionalTeam =[]
                cadetsFemaleTraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 14)
                }))
                if (cadetsFemaleTraditionalTeam[0].length > 0) result.push({category:'cadetsFemaleTraditionalTeam'+classSwitcher,athletes:cadetsFemaleTraditionalTeam} )
                        //ЮНІОРИ: чоловіки Команди
                const juniorsMaleTraditionalTeam =[]
                 juniorsMaleTraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 15 && state.age(at.birthday) <= 17)
                }))
                if (juniorsMaleTraditionalTeam[0].length > 0) result.push({category:'juniorsMaleTraditionalTeam'+classSwitcher,athletes:juniorsMaleTraditionalTeam} )
                        //ЮНІОРИ: жінки Команди
                const juniorsFemaleTraditionalTeam =[]
                juniorsFemaleTraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 15 && state.age(at.birthday) <= 17)
                }))
                if (juniorsFemaleTraditionalTeam[0].length > 0) result.push({category:'juniorsFemaleTraditionalTeam'+classSwitcher,athletes:juniorsFemaleTraditionalTeam} )
                        //ДОРОСЛІ ДО 30: чоловіки Команди
                const adultsMale30TraditionalTeam =[]
                adultsMale30TraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 30)
                }))
                if (adultsMale30TraditionalTeam[0].length > 0) result.push({category:'adultsMale30TraditionalTeam'+classSwitcher,athletes:adultsMale30TraditionalTeam} )
                        //ДОРОСЛІ ДО 30: жінки Команди
                const adultsFemale30TraditionalTeam =[]
                adultsFemale30TraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 30)
                }))
                if (adultsFemale30TraditionalTeam[0].length > 0) result.push({category:'adultsFemale30TraditionalTeam'+classSwitcher,athletes:adultsFemale30TraditionalTeam} )
                        //ДОРОСЛІ ДО 40: чоловіки Команди
                const adultsMale40TraditionalTeam =[]
                 adultsMale40TraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 31 && state.age(at.birthday) <= 40)
                }))
                if (adultsMale40TraditionalTeam[0].length > 0) result.push({category:'adultsMale40TraditionalTeam'+classSwitcher,athletes:adultsMale40TraditionalTeam} )
                         //ДОРОСЛІ ДО 40: жінки Команди
                const adultsFemale40TraditionalTeam =[]
                 adultsFemale40TraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 31 && state.age(at.birthday) <= 40)
                }))
                if (adultsFemale40TraditionalTeam[0].length > 0) result.push({category:'adultsFemale40TraditionalTeam'+classSwitcher,athletes:adultsFemale40TraditionalTeam} )
                        //ДОРОСЛІ ДО 50: чоловіки Команди
                const adultsMale50TraditionalTeam =[]
                adultsMale50TraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 41 && state.age(at.birthday) <= 50)
                }))
                if (adultsMale50TraditionalTeam[0].length > 0) result.push({category:'adultsMale50TraditionalTeam'+classSwitcher,athletes:adultsMale50TraditionalTeam} )
                         //ДОРОСЛІ ДО 50: жінки Команди
                const adultsFemale50TraditionalTeam =[]
                adultsFemale50TraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 41 && state.age(at.birthday) <= 50)
                }))
                if (adultsFemale50TraditionalTeam[0].length > 0)result.push({category:'adultsFemale50TraditionalTeam'+classSwitcher,athletes:adultsFemale50TraditionalTeam} )
                        //ДОРОСЛІ ДО 60: чоловіки Команди
                const adultsMale60TraditionalTeam =[]
                adultsMale60TraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 51 && state.age(at.birthday) <= 60)
                }))
                if (adultsMale60TraditionalTeam[0].length > 0) result.push({category:'adultsMale60TraditionalTeam'+classSwitcher,athletes:adultsMale60TraditionalTeam} )
                         //ДОРОСЛІ ДО 60: жінки Команди
                const adultsFemale60TraditionalTeam =[]
                 adultsFemale60TraditionalTeam.push(entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 51 && state.age(at.birthday) <= 60)
                }))
                if (adultsFemale60TraditionalTeam[0].length > 0) result.push({category:'adultsFemale60TraditionalTeam'+classSwitcher,athletes:adultsFemale60TraditionalTeam} )
                       //ДОРОСЛІ ПОНАД 60: чоловіки Команди
                const adultsMale60PlusTraditionalTeam =[]
                adultsMale60PlusTraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 61 )
                }))
                if (adultsMale60PlusTraditionalTeam[0].length > 0) result.push({category:'adultsMale60PlusTraditionalTeam'+classSwitcher,athletes:adultsMale60PlusTraditionalTeam} )
                     //ДОРОСЛІ ПОНАД 60: жінки Команди
                const adultsFemale60PlusTraditionalTeam =[]
                adultsFemale60PlusTraditionalTeam.push( entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 61)
                }))
                if (adultsFemale60PlusTraditionalTeam[0].length > 0) result.push({category:'adultsFemale60PlusTraditionalTeam'+classSwitcher,athletes:adultsFemale60PlusTraditionalTeam} )
                       
                return result    
                    }                    
                },
            },
            freestyle:{
                personal:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []

                            //до 17: чоловіки freestyle особисті
                const freestylePersonaleMale17 = entryAthletes.filter((at) => {
                    return (at.sex === 'male' &&state.age(at.birthday) >= 12 && state.age(at.birthday) <= 17 )
                })
                if (freestylePersonaleMale17.length > 0) result.push({category:'freestylePersonaleMale17'+classSwitcher,athletes:freestylePersonaleMale17} )
                        //до 17: жінки freestyle особисті
                const freestylePersonaleFemale17 = entryAthletes.filter((at) => {
                    return (at.sex === 'female' &&state.age(at.birthday) >= 12 && state.age(at.birthday) <= 17)
                })
                if (freestylePersonaleFemale17.length > 0) result.push({category:'freestylePersonaleFemale17'+classSwitcher,athletes:freestylePersonaleFemale17} )
                        //Понад 17: чоловіки freestyle особисті
                const freestylePersonaleMale17Plus = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 17 )
                })
                if (freestylePersonaleMale17Plus.length > 0) result.push({category:'freestylePersonaleMale17Plus'+classSwitcher,athletes:freestylePersonaleMale17Plus} )
                        //Понад 17: жінки freestyle особисті
                const freestylePersonaleFemale17Plus = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 17)
                })
                if (freestylePersonaleFemale17Plus.length > 0) result.push({category:'freestylePersonaleFemale17Plus'+classSwitcher,athletes:freestylePersonaleFemale17Plus} )
                        
                   
                   return result 
                    }
                },
                couple:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []

                            //до 17:  freestyle пари
                const freestyleCouple17 = []
                freestyleCouple17.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 12 &&state.age(at.birthday) <= 17 )
                }))
                if (freestyleCouple17[0].length > 0) result.push({category:'freestyleCouple17'+classSwitcher,athletes:freestyleCouple17} )
                        //Понад 17:  freestyle пари
                const freestyleCouple17Plus =[]
                freestyleCouple17Plus.push( entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 17 )
                }))
                if (freestyleCouple17Plus[0].length > 0) result.push({category:'freestyleCouple17Plus'+classSwitcher,athletes:freestyleCouple17Plus} )
                        
                return result 
                    }
                },
                team:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []
                
                        //Фрістайл Пхумсе команди
                const freestyleTeam = []
                freestyleTeam.push(entryAthletes)
                if (freestyleTeam[0].length > 0) result.push({category:'freestyleTeam'+classSwitcher,athletes:freestyleTeam} )
                        return result
                    }}
            },
            mixPoomsae:{
                personal:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []

                         //Понад 17: Змішане пхумсе особисті чоловіки
                const mixPoomsaePersonaleMale = entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 18 )
                })
                if (mixPoomsaePersonaleMale.length > 0) result.push({category:'mixPoomsaePersonaleMale'+classSwitcher,athletes:mixPoomsaePersonaleMale} )
                        
                //Понад 17: Змішане пхумсе особисті Жінки
                const mixPoomsaePersonaleFemale = entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 18 )
                })
                if (mixPoomsaePersonaleFemale.length > 0) result.push({category:'mixPoomsaePersonaleFemale'+classSwitcher,athletes:mixPoomsaePersonaleFemale} )
                        
                return result 
                }
                },
                couple:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []

                //Понад 17: Змішане пхумсе парні
                const mixPoomsaeCouple =[]
                mixPoomsaeCouple.push( entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 18 )
                }))
                if (mixPoomsaeCouple[0].length > 0) result.push({category:'mixPoomsaeCouple'+classSwitcher,athletes:mixPoomsaeCouple} )
                        
                return result 
                }
                },
                team:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []

                         //Понад 17: Змішане пхумсе Командні чоловіки
                const mixPoomsaeTeamMale =[]
                 mixPoomsaeTeamMale.push(entryAthletes.filter((at) => {
                    return (at.sex === 'male' && state.age(at.birthday) >= 18 )
                }))
                if (mixPoomsaeTeamMale[0].length > 0) result.push({category:'mixPoomsaeTeamMale'+classSwitcher,athletes:mixPoomsaeTeamMale} )
                        
                //Понад 17: Змішане пхумсе Командні Жінки
                const mixPoomsaeTeamFemale =[]
                 mixPoomsaeTeamFemale.push(entryAthletes.filter((at) => {
                    return (at.sex === 'female' && state.age(at.birthday) >= 18 )
                }))
                if (mixPoomsaeTeamFemale[0].length > 0) result.push({category:'mixPoomsaeTeamFemale'+classSwitcher,athletes:mixPoomsaeTeamFemale} )
                        
                return result 
                }
                },
                mixedTeam:(entryAthletes,classSwitcher)=>{
                    if (entryAthletes) {
                        try { entryAthletes = JSON.parse(entryAthletes) }
                        catch (error) { }                        
                         const result = []
                
                        //Змішане Пхумсе Змішані команди
                const mixPoomsaeMixedTeam = []
                mixPoomsaeMixedTeam.push(entryAthletes.filter((at) => {
                    return (state.age(at.birthday) >= 18 )
                }))
                if (mixPoomsaeMixedTeam[0].length > 0) result.push({category:'mixPoomsaeMixedTeam'+classSwitcher,athletes:mixPoomsaeMixedTeam} )
                        return result
                    }}
            }
        },              
    },
    checkAndDisplayTeams: (teams) => {//Перевіряємо чи усі спортсмени заявлені від однієї команди (для друку заявки)
        // Перевірка, чи всі значення в масиві є однаковими
        const allEqual = teams.every(team => team === teams[0]);

        if (allEqual) {
            // Всі значення однакові, виводимо одне значення
            return teams[0]
        } else {
            // Значення різні, знаходимо значення, яке прописано в більшості ячейок
            const counts = {};
            let mostFrequentValue = teams[0];
            let maxCount = 1;

            teams.forEach(value => {
                counts[value] = (counts[value] || 0) + 1;

                if (counts[value] > maxCount) {
                    mostFrequentValue = value;
                    maxCount = counts[value];
                }
            });

            return mostFrequentValue

        }
    },

    myCoach: (numCoach) => { //виводить П.І.Б тренера, замість його id
        let myCoach = state.athletes.filter(athlete => parseInt(athlete.id) == numCoach)[0];
        if (myCoach) {
            let result = myCoach.secondName +
                (myCoach.personName && myCoach.personName[0] ? ` ${myCoach.personName[0].toUpperCase()}.` : '') +
                (myCoach.fatherName && myCoach.fatherName[0] ? ` ${myCoach.fatherName[0].toUpperCase()}.` : '');

            return result;
        }
        return '';
    },
    myCoachFull: (numCoach) => { //виводить повне П.І.Б тренера, 
        let myCoach = state.athletes.filter(athlete => parseInt(athlete.id) == numCoach)[0];
        if (myCoach) {
            let result = `${myCoach.secondName} ${myCoach.personName} ${myCoach.fatherName}`

            return result;
        }
        return '';
    },
    athleteName: (id) => {
       
        let profile = state.athletes.filter(athlete => parseInt(athlete.id) == id)[0];
        let result
       
        if(profile)
    {result = (`${profile.secondName} ${profile.personName} `)}
        else {result=`Профіль Видалено (id: ${id})`}
        return (<b>{result}</b>)
    },
    athleteNameShort: (numCoach) => { //виводить П.І.Б тренера, замість його id
        let myCoach = state.athletes.filter(athlete => parseInt(athlete.id) == numCoach)[0];
        if (myCoach) {
            let result = myCoach.secondName +
                (myCoach.personName && myCoach.personName[0] ? ` ${myCoach.personName[0].toUpperCase()}.` : '')


            return (<b>{result}</b>)
        }
        return '';
    },
    poomsaeTeam: (team) => {
     
        return (<div>
            {team.map((item, index) => {
               
                return (<span key={item.id}>
                    <b>{item.secondName} {item.personName[0].toUpperCase()}.&nbsp;
                    </b>{index === 2&&team.length!==3 ? (<><br /></>) : null}&nbsp;
                </span>)
            })}
        </div>)
    },
    poomsaeTeamString: (team) => {
        return team.map((item, index) => {
            const fullName = `${item.secondName} ${item.personName[0].toUpperCase()}.`;
            const separator = (index === 2 && team.length !== 3) ? '\n' : ' ';
            return `${fullName}${separator}`;
        }).join('').trim();
    },
    ageCatUkr: (categories) => {//Перекладаємо вікові категорії українською
        if (!Array.isArray(categories)) {
            console.error('Помилка: Очікується масив для функції ageCatUkr');
            return categories; // повертаємо вхідні дані, оскільки це не масив
        }
        const result = categories.map((cat) => {
            if (cat === 'jrChildren') return 'Малюки, ';
            if (cat === 'children') return 'Діти, ';
            if (cat === 'jrYounkers') return 'Молодші юнаки, ';
            if (cat === 'younkers') return 'Юнаки, ';
            if (cat === 'cadets') return 'Кадети, ';
            if (cat === 'juniors') return 'Юніори, ';
            if (cat === 'adults') return 'Дорослі, ';
            if (cat === 'veterans') return 'Ветерани.';

            return cat; // повернути оригінальне значення, якщо нічого не підходить
        });

        return result; // повернути новий масив
    },
    weightCatUkr: (category) => {//Перекладаємо вагові категорії українською

        if (category === 'jrChildrenMale18') { return ('Малюки: чол. до 18кг.') }
        if (category === 'jrChildrenMale20') { return ('Малюки: чол. до 20кг.') }
        if (category === 'jrChildrenMale22') { return ('Малюки: чол. до 22кг.') }
        if (category === 'jrChildrenMale24') { return ('Малюки: чол. до 24кг.') }
        if (category === 'jrChildrenMale26') { return ('Малюки: чол. до 26кг.') }
        if (category === 'jrChildrenMale28') { return ('Малюки: чол. до 28кг.') }
        if (category === 'jrChildrenMale30') { return ('Малюки: чол. до 30кг.') }
        if (category === 'jrChildrenMale35') { return ('Малюки: чол. до 35кг.') }
        if (category === 'jrChildrenMale35Plus') { return ('Малюки: чол. понад 35кг.') }

        if (category === 'jrChildrenFemale18') { return ('Малюки: дів. до 18кг.') }
        if (category === 'jrChildrenFemale20') { return ('Малюки: дів. до 20кг.') }
        if (category === 'jrChildrenFemale22') { return ('Малюки: дів. до 22кг.') }
        if (category === 'jrChildrenFemale24') { return ('Малюки: дів. до 24кг.') }
        if (category === 'jrChildrenFemale26') { return ('Малюки: дів. до 26кг.') }
        if (category === 'jrChildrenFemale28') { return ('Малюки: дів. до 28кг.') }
        if (category === 'jrChildrenFemale30') { return ('Малюки: дів. до 30кг.') }
        if (category === 'jrChildrenFemale35') { return ('Малюки: дів. до 35кг.') }
        if (category === 'jrChildrenFemale35Plus') { return ('Малюки: дів. понад 35кг.') }

        if (category === 'childrenMale20') { return ('Діти: чол. до 20кг.') }
        if (category === 'childrenMale22') { return ('Діти: чол. до 22кг.') }
        if (category === 'childrenMale24') { return ('Діти: чол. до 24кг.') }
        if (category === 'childrenMale26') { return ('Діти: чол. до 26кг.') }
        if (category === 'childrenMale28') { return ('Діти: чол. до 28кг.') }
        if (category === 'childrenMale30') { return ('Діти: чол. до 30кг.') }
        if (category === 'childrenMale35') { return ('Діти: чол. до 35кг.') }
        if (category === 'childrenMale40') { return ('Діти: чол. до 40кг.') }
        if (category === 'childrenMale40Plus') { return ('Діти: чол. понад 40кг.') }

        if (category === 'childrenFemale18') { return ('Діти: жін. до 18кг.') }
        if (category === 'childrenFemale20') { return ('Діти: жін. до 20кг.') }
        if (category === 'childrenFemale22') { return ('Діти: жін. до 22кг.') }
        if (category === 'childrenFemale24') { return ('Діти: жін. до 24кг.') }
        if (category === 'childrenFemale26') { return ('Діти: жін. до 26кг.') }
        if (category === 'childrenFemale28') { return ('Діти: жін. до 28кг.') }
        if (category === 'childrenFemale30') { return ('Діти: жін. до 30кг.') }
        if (category === 'childrenFemale35') { return ('Діти: жін. до 35кг.') }
        if (category === 'childrenFemale35Plus') { return ('Діти: жін. понад 35кг.') }

        if (category === 'jrYounkersMale24') { return ('Мол.Юн.: чол. до 24кг.') }
        if (category === 'jrYounkersMale26') { return ('Мол.Юн.: чол. до 26кг.') }
        if (category === 'jrYounkersMale28') { return ('Мол.Юн.: чол. до 28кг.') }
        if (category === 'jrYounkersMale30') { return ('Мол.Юн.: чол. до 30кг.') }
        if (category === 'jrYounkersMale35') { return ('Мол.Юн.: чол. до 35кг.') }
        if (category === 'jrYounkersMale40') { return ('Мол.Юн.: чол. до 40кг.') }
        if (category === 'jrYounkersMale45') { return ('Мол.Юн.: чол. до 45кг.') }
        if (category === 'jrYounkersMale50') { return ('Мол.Юн.: чол. до 50кг') }
        if (category === 'jrYounkersMale50Plus') { return ('Мол.Юн.: чол. понад 50кг.') }        

        if (category === 'jrYounkersFemale24') { return ('Мол.Юн.: жін. до 24кг.') }
        if (category === 'jrYounkersFemale26') { return ('Мол.Юн.: жін. до 26кг.') }
        if (category === 'jrYounkersFemale28') { return ('Мол.Юн.: жін. до 28кг.') }
        if (category === 'jrYounkersFemale30') { return ('Мол.Юн.: жін. до 30кг.') }
        if (category === 'jrYounkersFemale35') { return ('Мол.Юн.: жін. до 35кг.') }
        if (category === 'jrYounkersFemale40') { return ('Мол.Юн.: жін. до 40кг.') }
        if (category === 'jrYounkersFemale45') { return ('Мол.Юн.: жін. до 45кг.') }
        if (category === 'jrYounkersFemale50') { return ('Мол.Юн.: жін. до 50кг.') }
        if (category === 'jrYounkersFemale50Plus') { return ('Мол.Юн.: жін. понад 50кг.') }

        if (category === 'younkersMale30') { return ('Юнаки: чол. до 30кг.') }
        if (category === 'younkersMale35') { return ('Юнаки: чол. до 35кг.') }
        if (category === 'younkersMale40') { return ('Юнаки: чол. до 40кг.') }
        if (category === 'younkersMale45') { return ('Юнаки: чол. до 45кг.') }
        if (category === 'younkersMale50') { return ('Юнаки: чол. до 50кг.') }
        if (category === 'younkersMale55') { return ('Юнаки: чол. до 55кг.') }
        if (category === 'younkersMale60') { return ('Юнаки: чол. до 60кг.') }
        if (category === 'younkersMale65') { return ('Юнаки: чол. до 65кг.') }
        if (category === 'younkersMale65Plus') { return ('Юнаки: чол. понад 65кг.') }

        if (category === 'younkersFemale30') { return ('Юнаки: жін. до 30кг.') }
        if (category === 'younkersFemale35') { return ('Юнаки: жін. до 35кг.') }
        if (category === 'younkersFemale40') { return ('Юнаки: жін. до 40кг.') }
        if (category === 'younkersFemale45') { return ('Юнаки: жін. до 45кг.') }
        if (category === 'younkersFemale50') { return ('Юнаки: жін. до 50кг.') }
        if (category === 'younkersFemale55') { return ('Юнаки: жін. до 55кг.') }
        if (category === 'younkersFemale60') { return ('Юнаки: жін. до 60кг.') }
        if (category === 'younkersFemale65') { return ('Юнаки: жін. до 65кг.') }
        if (category === 'younkersFemale65Plus') { return ('Юнаки: жін. понад 65кг.') }

        if (category === 'cadetsMale35') { return ('Кадети: чол. до 35кг.') }
        if (category === 'cadetsMale40') { return ('Кадети: чол. до 40кг.') }
        if (category === 'cadetsMale45') { return ('Кадети: чол. до 45кг.') }
        if (category === 'cadetsMale50') { return ('Кадети: чол. до 50кг.') }
        if (category === 'cadetsMale55') { return ('Кадети: чол. до 55кг.') }
        if (category === 'cadetsMale60') { return ('Кадети: чол. до 60кг.') }
        if (category === 'cadetsMale65') { return ('Кадети: чол. до 65кг.') }
        if (category === 'cadetsMale70') { return ('Кадети: чол. до 70кг.') }
        if (category === 'cadetsMale75') { return ('Кадети: чол. до 75кг.') }
        if (category === 'cadetsMale75Plus') { return ('Кадети: чол. понад 75кг.') }

        if (category === 'cadetsFemale35') { return ('Кадети: жін. до 35кг.') }
        if (category === 'cadetsFemale40') { return ('Кадети: жін. до 40кг.') }
        if (category === 'cadetsFemale45') { return ('Кадети: жін. до 45кг.') }
        if (category === 'cadetsFemale50') { return ('Кадети: жін. до 50кг.') }
        if (category === 'cadetsFemale55') { return ('Кадети: жін. до 55кг.') }
        if (category === 'cadetsFemale60') { return ('Кадети: жін. до 60кг.') }
        if (category === 'cadetsFemale65') { return ('Кадети: жін. до 65кг.') }
        if (category === 'cadetsFemale70') { return ('Кадети: жін. до 70кг.') }
        if (category === 'cadetsFemale75') { return ('Кадети: жін. до 75кг.') }
        if (category === 'cadetsFemale75Plus') { return ('Кадети: жін. понад 75кг.') }

        if (category === 'juniorsMale45') { return ('Юніори: чол. до 45кг.') }
        if (category === 'juniorsMale50') { return ('Юніори: чол. до 50кг.') }
        if (category === 'juniorsMale55') { return ('Юніори: чол. до 55кг.') }
        if (category === 'juniorsMale60') { return ('Юніори: чол. до 60кг.') }
        if (category === 'juniorsMale65') { return ('Юніори: чол. до 65кг.') }
        if (category === 'juniorsMale70') { return ('Юніори: чол. до 70кг.') }
        if (category === 'juniorsMale75') { return ('Юніори: чол. до 75кг.') }
        if (category === 'juniorsMale80') { return ('Юніори: чол. до 80кг.') }
        if (category === 'juniorsMale85') { return ('Юніори: чол. до 85кг.') }
        if (category === 'juniorsMale85Plus') { return ('Юніори: чол. понад 85кг.') }

        if (category === 'juniorsFemale40') { return ('Юніори: жін. до 40кг.') }
        if (category === 'juniorsFemale45') { return ('Юніори: жін. до 45кг.') }
        if (category === 'juniorsFemale50') { return ('Юніори: жін. до 50кг.') }
        if (category === 'juniorsFemale55') { return ('Юніори: жін. до 55кг.') }
        if (category === 'juniorsFemale60') { return ('Юніори: жін. до 60кг.') }
        if (category === 'juniorsFemale65') { return ('Юніори: жін. до 65кг.') }
        if (category === 'juniorsFemale70') { return ('Юніори: жін. до 70кг.') }
        if (category === 'juniorsFemale75') { return ('Юніори: жін. до 75кг.') }
        if (category === 'juniorsFemale80') { return ('Юніори: жін. до 80кг.') }
        if (category === 'juniorsFemale80Plus') { return ('Юніори: жін. понад 80кг.') }

        if (category === 'adultsMale55') { return ('Дорослі: чол. до 55кг.') }
        if (category === 'adultsMale60') { return ('Дорослі: чол. до 60кг.') }
        if (category === 'adultsMale65') { return ('Дорослі: чол. до 65кг.') }
        if (category === 'adultsMale70') { return ('Дорослі: чол. до 70кг.') }
        if (category === 'adultsMale75') { return ('Дорослі: чол. до 75кг.') }
        if (category === 'adultsMale80') { return ('Дорослі: чол. до 80кг.') }
        if (category === 'adultsMale85') { return ('Дорослі: чол. до 85кг.') }
        if (category === 'adultsMale90') { return ('Дорослі: чол. до 90кг.') }
        if (category === 'adultsMale100') { return ('Дорослі: чол. до 100кг.') }
        if (category === 'adultsMale100Plus') { return ('Дорослі: чол. понад 100кг.') }

        if (category === 'adultsFemale45') { return ('Дорослі: жін. до 45кг.') }
        if (category === 'adultsFemale50') { return ('Дорослі: жін. до 50кг.') }
        if (category === 'adultsFemale55') { return ('Дорослі: жін. до 55кг.') }
        if (category === 'adultsFemale60') { return ('Дорослі: жін. до 60кг.') }
        if (category === 'adultsFemale65') { return ('Дорослі: жін. до 65кг.') }
        if (category === 'adultsFemale70') { return ('Дорослі: жін. до 70кг.') }
        if (category === 'adultsFemale75') { return ('Дорослі: жін. до 75кг.') }
        if (category === 'adultsFemale80') { return ('Дорослі: жін. до 80кг.') }
        if (category === 'adultsFemale85') { return ('Дорослі: жін. до 85кг.') }
        if (category === 'adultsFemale85Plus') { return ('Дорослі: жін. понад 85кг.') }

        if (category === 'veteransMale55') { return ('Ветерани: чол. до 55кг.') }
        if (category === 'veteransMale60') { return ('Ветерани: чол. до 60кг.') }
        if (category === 'veteransMale65') { return ('Ветерани: чол. до 65кг.') }
        if (category === 'veteransMale70') { return ('Ветерани: чол. до 70кг.') }
        if (category === 'veteransMale75') { return ('Ветерани: чол. до 75кг.') }
        if (category === 'veteransMale80') { return ('Ветерани: чол. до 80кг.') }
        if (category === 'veteransMale85') { return ('Ветерани: чол. до 85кг.') }
        if (category === 'veteransMale90') { return ('Ветерани: чол. до 90кг.') }
        if (category === 'veteransMale100') { return ('Ветерани: чол. до 100кг.') }
        if (category === 'veteransMale100Plus') { return ('Ветерани: чол. понад 100кг.') }

        if (category === 'veteransFemale45') { return ('Ветерани: жін. до 45кг.') }
        if (category === 'veteransFemale50') { return ('Ветерани: жін. до 50кг.') }
        if (category === 'veteransFemale55') { return ('Ветерани: жін. до 55кг.') }
        if (category === 'veteransFemale60') { return ('Ветерани: жін. до 60кг.') }
        if (category === 'veteransFemale65') { return ('Ветерани: жін. до 65кг.') }
        if (category === 'veteransFemale70') { return ('Ветерани: жін. до 70кг.') }
        if (category === 'veteransFemale75') { return ('Ветерани: жін. до 75кг.') }
        if (category === 'veteransFemale80') { return ('Ветерани: жін. до 80кг.') }
        if (category === 'veteransFemale85') { return ('Ветерани: жін. до 85кг.') }
        if (category === 'veteransFemale85Plus') { return ('Ветерани: жін. понад 85кг.') }
  
    },
    regionUkr: (region) => {//Відміяємо назви обласних осередків у родовий відмінок(для друку заявки)
        let result
        if (region === 'Івано-Франківський обласний осередок тхеквондо (ВТФ)') { result = 'Івано-Франківської області' }
        if (region === 'Вінницький обласний осередок тхеквондо (ВТФ)') { result = 'Вінницької області' }
        if (region === 'Волинський обласний осередок тхеквондо (ВТФ)') { result = 'Волинської області' }
        if (region === 'Дніпропетровський обласний осередок тхеквондо (ВТФ)') { result = 'Дніпропетровської області' }
        if (region === 'Донецький обласний осередок тхеквондо (ВТФ)') { result = 'Донецької області' }
        if (region === 'Житомирський обласний осередок тхеквондо (ВТФ)') { result = 'Житомирської області' }
        if (region === 'Закарпатський обласний осередок тхеквондо (ВТФ)') { result = 'Закарпатської області' }
        if (region === 'Запорізький обласний осередок тхеквондо (ВТФ)') { result = 'Запорізької області' }
        if (region === 'Кропівницький обласний осередок тхеквондо (ВТФ)') { result = 'Кропівницької області' }
        if (region === 'Київський обласний осередок тхеквондо (ВТФ)') { result = 'Київської області' }
        if (region === 'Луганський обласний осередок тхеквондо (ВТФ)') { result = 'Луганської області' }
        if (region === 'Львівський обласний осередок тхеквондо (ВТФ)') { result = 'Львівської області' }
        if (region === 'Миколайвський обласний осередок тхеквондо (ВТФ)') { result = 'Миколайвської області' }
        if (region === 'Одеський обласний осередок тхеквондо (ВТФ)') { result = 'Одеської області' }
        if (region === 'Полтавський обласний осередок тхеквондо (ВТФ)') { result = 'Полтавської області' }
        if (region === 'Рівненський обласний осередок тхеквондо (ВТФ)') { result = 'Рівненської області' }
        if (region === 'Сумський обласний осередок тхеквондо (ВТФ)') { result = 'Сумської області' }
        if (region === 'Тернопільський обласний осередок тхеквондо (ВТФ)') { result = 'Тернопільської області' }
        if (region === 'Харківський обласний осередок тхеквондо (ВТФ)') { result = 'Харківської області' }
        if (region === 'Херсонський обласний осередок тхеквондо (ВТФ)') { result = 'Херсонської області' }
        if (region === 'Хмельницький обласний осередок тхеквондо (ВТФ)') { result = 'Хмельницької області' }
        if (region === 'Черкаський обласний осередок тхеквондо (ВТФ)') { result = 'Черкаської області' }
        if (region === 'Чернівецький обласний осередок тхеквондо (ВТФ)') { result = 'Чернівецької області' }
        if (region === 'Чернігівський обласний осередок тхеквондо (ВТФ)') { result = 'Чернігівської області' }
        return result
    },
    regionShort: (region) => {//Скорочуємо назви обласних осередків у родовий відмінок(для друку заявки)
        let result
        if (region === 'Івано-Франківський обласний осередок тхеквондо (ВТФ)') { result = 'Ів.Франківська.обл.' }
        else if (region === 'Вінницький обласний осередок тхеквондо (ВТФ)') { result = 'Вінницька обл.' }
        else if (region === 'Волинський обласний осередок тхеквондо (ВТФ)') { result = 'Волинська обл.' }
        else if (region === 'Дніпропетровський обласний осередок тхеквондо (ВТФ)') { result = 'Дніпровська обл.' }
        else if (region === 'Донецький обласний осередок тхеквондо (ВТФ)') { result = 'Донецька обл.' }
        else if (region === 'Житомирський обласний осередок тхеквондо (ВТФ)') { result = 'Житомирська обл.' }
        else if (region === 'Закарпатський обласний осередок тхеквондо (ВТФ)') { result = 'Закарпатська обл.' }
        else if (region === 'Запорізький обласний осередок тхеквондо (ВТФ)') { result = 'Запорізька обл.' }
        else if (region === 'Кропівницький обласний осередок тхеквондо (ВТФ)') { result = 'Кропівницька обл.' }
        else if (region === 'Київський обласний осередок тхеквондо (ВТФ)') { result = 'Київська обл.' }
        else if (region === 'Луганський обласний осередок тхеквондо (ВТФ)') { result = 'Луганська обл.' }
        else if (region === 'Львівський обласний осередок тхеквондо (ВТФ)') { result = 'Львівська обл.' }
        else if (region === 'Миколайвський обласний осередок тхеквондо (ВТФ)') { result = 'Миколайвська обл.' }
        else if (region === 'Одеський обласний осередок тхеквондо (ВТФ)') { result = 'Одеська обл.' }
        else if (region === 'Полтавський обласний осередок тхеквондо (ВТФ)') { result = 'Полтавська обл.' }
        else if (region === 'Рівненський обласний осередок тхеквондо (ВТФ)') { result = 'Рівненська обл.' }
        else if (region === 'Сумський обласний осередок тхеквондо (ВТФ)') { result = 'Сумська обл.' }
        else if (region === 'Тернопільський обласний осередок тхеквондо (ВТФ)') { result = 'Тернопільська обл.' }
        else if (region === 'Харківський обласний осередок тхеквондо (ВТФ)') { result = 'Харківська обл.' }
        else if (region === 'Херсонський обласний осередок тхеквондо (ВТФ)') { result = 'Херсонська обл.' }
        else if (region === 'Хмельницький обласний осередок тхеквондо (ВТФ)') { result = 'Хмельницька обл.' }
        else if (region === 'Черкаський обласний осередок тхеквондо (ВТФ)') { result = 'Черкаська обл.' }
        else if (region === 'Чернівецький обласний осередок тхеквондо (ВТФ)') { result = 'Чернівецька обл.' }
        else if (region === 'Чернігівський обласний осередок тхеквондо (ВТФ)') { result = 'Чернігівська обл.' }
        else { result = region }
        return result
    },


    stateUpdate: () => {           
    
        // Завантажуємо БД в стан
        indexedDBConnection.loadData('athletes').then((athletesData) => {
            if (athletesData.length === 0) { // Якщо дані відсутні в IndexedDB
                axios.get(state.api.getAthletes)
                    .then((response) => {                      
                            indexedDBConnection.saveData('athletes', response.data.athletes);
                            state.athletes = response.data.athletes; // Оновлення стану                         
                    })
                    .catch((error) => {
                        console.error('Помилка запиту до сервера:', error);
                    });
            } else {
                state.athletes = athletesData; // Завантаження з IndexedDB
            }
        });
    
        indexedDBConnection.loadData('competition').then((competitionData) => {
            if (competitionData.length === 0) {
                axios.get(state.api.getCompetition)
                .then((response) => {                   
                        indexedDBConnection.saveData('competition',  response.data.competition);
                        state.competition = response.data.competition; // Оновлення стану                     
                })
                    .catch((error) => {
                        console.error('Помилка запиту до сервера:', error);
                    });
            } else {
                state.competition = competitionData; // Завантаження з IndexedDB
            }
        });
    
        indexedDBConnection.loadData('sportClubs').then((sportClubsData) => {
            if (sportClubsData.length === 0) {
                axios.get(state.api.getSportClub)
                .then((response) => {               
                        indexedDBConnection.saveData('sportClubs', response.data.sportclubs);
                        state.sportClubs = response.data.sportclubs; // Оновлення стану                     
                })
                    .catch((error) => {
                        console.error('Помилка запиту до сервера:', error);
                    });
            } else {
                state.sportClubs = sportClubsData; // Завантаження з IndexedDB
            }
        });
    
        indexedDBConnection.loadData('mail').then((mailData) => {
            if (mailData.length === 0) {
                axios.get(state.api.getMail)
                .then((response) => {                   
                        indexedDBConnection.saveData('mail', response.data.mail);
                        state.mail = response.data.mail; // Оновлення стану                    
                })
                    .catch((error) => {
                        console.error('Помилка запиту до сервера:', error);
                    });
            } else {
                state.mail = mailData; // Завантаження з IndexedDB
            }
        });
    
        indexedDBConnection.loadData('archive').then((archiveData) => {
            if (archiveData.length === 0) {
                axios.get(state.api.getArchive)
                .then((response) => {                   
                        indexedDBConnection.saveData('archive', response.data.archive);
                        state.archive = response.data.archive; // Оновлення стану                    
                })
                    .catch((error) => {
                        console.error('Помилка запиту до сервера:', error);
                    });
            } else {
                state.archive = archiveData; // Завантаження з IndexedDB
            }
        });
        indexedDBConnection.saveData('userProfile',  state.userProfile);
    },
    
    testObjects: (objA, objB) => { //порівнюємо об'єкти, чи були внесені зміни при редагуванні профілю чи змаганнь
        // console.log('Порівнюємо об\'єкти:');
        // console.log('objA:', objA);
        // console.log('objB:', objB);

        let result = true; // Оголошуємо result тут і ініціалізуємо його як true

        function recursiveTest(objA, objB) {

            for (const key in objA) {
                // console.log(`Порівнюємо властивість "${key}":`);
                const valueA = objA[key];
                const valueB = objB[key];

                if (typeof valueA === 'object' && typeof valueB === 'object') {
                    // console.log('Це об\'єкти, запускаємо порівняння рекурсивно:');
                    const subResult = recursiveTest(valueA, valueB);
                    if (!subResult) {
                        // console.log(`Об'єкти властивості "${key}" не співпадають. Повертаємо false.`);
                        result = false; // Оновлюємо result на false, якщо є різниця
                    }
                } else if (valueA !== valueB) {
                    // console.log(`Значення властивості "${key}" не співпадають. Повертаємо false.`);
                    result = false; // Оновлюємо result на false, якщо є різниця
                } else {
                    // console.log(`Значення властивості "${key}" співпадають.`);
                }
            }
        }

        recursiveTest(objA, objB);

        // console.log(result ? 'Усі властивості об\'єктів співпадають. Повертаємо true.' : 'Є різниця в об\'єктах. Повертаємо false.');

        return result;
    },

    axiosForMessage: async (title, messageData, id) => {


        const formattedDate = state.dateForMessage(); // Поточна дата

        axios.post(state.api.postErrorMassage, {
            isRead: 0, // isRead має бути передано як false, а не false string
            text: messageData,
            title: title,
            date: formattedDate,
            respondent: id
        }).then(() => {        
                axios.get(state.api.getMail)
                    .then((response)=>{
                         state.mail=response.data.mail
                         indexedDBConnection.saveData ('mail',response.data.mail )
                    })
                    .catch((error)=>{console.log('помилка запиту на отримання повідомлень після надсилання поввдомлення: ',error);})
           
        }).catch((error) => {
            console.error('Помилка Axios-запиту:', error);
            throw error;
        })

    },
    
    errorMassageSend: (message, id,langSwitch) => {//Відправка повідомлення про відмову у реєстрації на змагання        


        //Вміст змінної "title" прописаний в умові в "PreviewWindow" його зміна приведе до помилки
        const title = lang[langSwitch].reasonsRefusalTitle /*Причини відмови спортсменам у реєстрації на турнир*/

        const messageData = message.join('')// об'єднуєм массив рядків в один рядок
    
        state.axiosForMessage(title, messageData, id)
    },

    messageCount: () => {// Лічильник непрочитаних повідомлень

        if (state.userProfile && state.userProfile.id > 0 && state.mail) {
            const filteredArray = state.mail.filter((item) => item.respondent == state.userProfile.id);

            // Додана перевірка, щоб уникнути помилок, якщо filteredArray є null або undefined
            if (filteredArray) {
                const unreadMessagesCount = filteredArray.filter(item => item.isRead === '0').length;
                state.unreadMailInProfile = unreadMessagesCount;
            }
        }
    },

    objectCopy: (originalObject) => { //функція для глибокого копіювання об'єктів
        let string = JSON.stringify(originalObject)
        let result = JSON.parse(string)
        return result
        //використання:  об'єктДубліка=objectCopy(орігінальнийОб'єкт)  
    },

    addCoach: (userId, coachId, coach1) => {//додаємо тренера через повідомлення

        //Вміст змінної "title" прописаний в умові в "PreviewWindow" його зміна приведе до помилки
        const title = 'Запит від спортсмена'
        const message = JSON.stringify({ 'userId': userId, 'coach1': coach1 })

        state.axiosForMessage(title, message, coachId)
    },
    removeKeys:(obj, keys)=>{ //вирізаємо єлементи об'єкта в сітках
        const removedEntries = {};
        keys.forEach(key => {
          if (obj.hasOwnProperty(key)) {
            removedEntries[key] = obj[key];
            delete obj[key];
          }
        });
        return removedEntries; // Повертаємо об'єкт видалених пар "ключ:значення"
      },
    exit:()=> {

        // Функція для очищення всіх даних з IndexedDB
        const clearIndexedDB = () => {
            return new Promise((resolve, reject) => {
                const request = indexedDB.open('myDatabase'); // Відкриваємо базу даних
                request.onerror = (event) => {
                    console.error('Помилка при відкритті IndexedDB:', event.target.errorCode);
                    reject(event.target.errorCode);
                };
    
                request.onsuccess = (event) => {
                    const db = event.target.result;
                    const objectStoreNames = Array.from(db.objectStoreNames); // Перетворюємо DOMStringList в масив
                    
                    // Очищення всіх об'єктних сховищ
                    const clearStoresPromises = objectStoreNames.map(storeName => {
                        return new Promise((resolve, reject) => {
                            const transaction = db.transaction(storeName, 'readwrite');
                            const store = transaction.objectStore(storeName);
                            
                            const clearRequest = store.clear();
                            clearRequest.onsuccess = () => {
                                resolve();
                            };
                            clearRequest.onerror = (event) => {
                                console.error('Помилка при очищенні сховища:', event.target.errorCode);
                                reject(event.target.errorCode);
                            };
                        });
                    });
    
                    // Чекаємо, поки всі об'єктні сховища будуть очищені
                    Promise.all(clearStoresPromises).then(() => {
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                };
    
                request.onupgradeneeded = () => {
                    // Нічого не робимо, якщо база даних ще не створена
                };
            });
        };
    
        // Викликаємо функцію для очищення IndexedDB та перенаправляємо користувача
        clearIndexedDB().then(() => {
            // Перезавантаження сторінки або перенаправлення
            window.location.href = '/Information';
        }).catch((error) => {
            console.error('Помилка при очищенні IndexedDB:', error);
            // Можна додати логіку для обробки помилок, якщо потрібно
        });

        localStorage.removeItem('controlDeskData')
    },
    dataForcutOfSystemResult:(resultDataTemp,athletes)=>{ //дані для виводу результатів змаганнь з Пумсе по системі виключень
        const result = athletes.map((item)=>{ 
             const poomsaeResult={place:[]}
             resultDataTemp.forEach((grid)=>{
                 grid.athletes.map((athlete)=>{
                     if(athlete.teamPoomsae){//командна заявка
                         athlete.teamPoomsae.forEach((teamMember)=>{
                             if(item.id==teamMember.id){
                                 poomsaeResult.place.push({
                                     category:grid.category,
                                     place:athlete.place,
                                     team:athlete.team,
                                     preliminaryResult:athlete.preliminaryResult,
                                     semiFinalResult:athlete.semiFinalResult,
                                     finalResults:athlete.finalResults,
                                     preliminaryResult_2:athlete.preliminaryResult_2,
                                     semiFinalResult_2:athlete.semiFinalResult_2,
                                     finalResults_2:athlete.finalResults_2,
                                     preliminaryTotal:athlete.preliminaryTotal,
                                     semiFinalTotal:athlete.semiFinalTotal,
                                     finalTotal:athlete.finalTotal,
                                     fullScore:athlete.fullScore,
                                     judgeScore:athlete.judgeScore,
                                     place:athlete.place
                                 })
                             }
                         })
                     }else{//індивідуальна заявка
                         if(item.id==athlete.id){
                             poomsaeResult.place.push({
                                category:grid.category,
                                place:athlete.place,
                                team:athlete.team,
                                preliminaryResult:athlete.preliminaryResult,
                                semiFinalResult:athlete.semiFinalResult,
                                finalResults:athlete.finalResults,
                                preliminaryResult_2:athlete.preliminaryResult_2,
                                semiFinalResult_2:athlete.semiFinalResult_2,
                                finalResults_2:athlete.finalResults_2,
                                preliminaryTotal:athlete.preliminaryTotal,
                                semiFinalTotal:athlete.semiFinalTotal,
                                finalTotal:athlete.finalTotal,
                                fullScore:athlete.fullScore,
                                judgeScore:athlete.judgeScore,
                                place:athlete.place
                             })
                         }
                     }
                 })//athlete
             })//grid
     
     
     
     
     
     
     
         return {...item,poomsaeResult}
        })//item
        return result
    },
    sortWinnerAthletes:(winnerAthletesData)=>{

      const  winnerAthletes = winnerAthletesData.sort((a, b) => {
            // Виділяємо першу цифру з competitionPlace або беремо саму цифру
            const placeA = parseInt(a.competitionPlace.toString().split(' ')[0], 10);
            const placeB = parseInt(b.competitionPlace.toString().split(' ')[0], 10);          
            return placeA - placeB;
          });
          const firstFourElements = winnerAthletesData.slice(0, 4);

          return firstFourElements
    }
   


}
export default state